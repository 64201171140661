import React, { useState, useEffect } from 'react';
import { useDrop, useDrag } from 'react-dnd';
import './schedule.css';

const SectionPeriodGrid = ({ teachers, sectionPeriods, preloadedAssociations, onGroupDrop }) => {
    const [droppedGroups, setDroppedGroups] = useState({});

    useEffect(() => {
        console.log("Initializing droppedGroups from preloadedAssociations:", preloadedAssociations);
        const initialDroppedGroups = {};
        if (preloadedAssociations && preloadedAssociations.length > 0) {
            preloadedAssociations.forEach(period => {
                if (period.teachers) {
                    period.teachers.forEach(teacher => {
                        const key = `${period.id}-${teacher.id}`;
                        initialDroppedGroups[key] = teacher.groups || [];
                    });
                }
            });
        }
        setDroppedGroups(initialDroppedGroups);
        console.log("Initialized droppedGroups:", initialDroppedGroups);
    }, [preloadedAssociations]);

    const handleDrop = (group, sectionPeriodId, teacherId) => {
        console.log("Handling drop with:", { group, sectionPeriodId, teacherId });

        if (sectionPeriodId === null) {
            console.log("Removing group:", group);
            onGroupDrop(group, null, teacherId);
            setDroppedGroups(prev => {
                const updated = { ...prev };
                const key = `${group.sectionPeriodId}-${teacherId}`;
                updated[key] = (updated[key] || []).filter(g => g.id !== group.id);
                console.log("Updated droppedGroups after removal:", updated);
                return updated;
            });
        } else {
            const key = `${sectionPeriodId}-${teacherId}`;
            console.log("Assigning group:", group, "to", key);
            onGroupDrop(group, sectionPeriodId, teacherId);
            setDroppedGroups(prev => ({
                ...prev,
                [key]: [...(prev[key] || []), group],
            }));
            console.log("Updated droppedGroups after assignment:", droppedGroups);
        }
    };

    const renderHeaderRow = () => {
        console.log("Rendering header row with teachers:", teachers);

        if (!teachers || teachers.length === 0) {
            console.warn("No teachers found. Rendering default headers.");
            return (
                <tr>
                    <th className="time-slot">Time</th>
                    <th className="teacher-header">No Teachers Assigned</th>
                </tr>
            );
        }

        return (
            <tr>
                <th className="time-slot">Time</th>
                {teachers.map((teacher) => (
                    <th key={teacher.id} className="teacher-header">{teacher.last_name || "Unnamed Teacher"}</th>
                ))}
            </tr>
        );
    };

    const renderTableBody = () => {
        console.log("Rendering table body with sectionPeriods:", sectionPeriods);
        return sectionPeriods.map((period) => (
            <tr key={period.id}>
                <td className="time-slot">{`${period.start_time} - ${period.end_time}`}</td>
                {teachers.map((teacher) => (
                    <DroppableCell
                        key={`${period.id}-${teacher.id}`}
                        sectionPeriodId={period.id}
                        teacherId={teacher.id}
                        droppedGroups={droppedGroups[`${period.id}-${teacher.id}`] || []}
                        onDrop={handleDrop}  // Ensure this is correctly referenced
                    />
                ))}
            </tr>
        ));
    };

    return (
        <div className="schedule-container">
            <table className="schedule-grid">
                <thead>
                    {renderHeaderRow()}
                </thead>
                <tbody>
                    {renderTableBody()}
                </tbody>
            </table>
            <TrashDropZone onGroupRemove={handleDrop} />
        </div>
    );
};

const DroppableCell = ({ sectionPeriodId, teacherId, droppedGroups, onDrop }) => {
    console.log("Rendering DroppableCell for:", { sectionPeriodId, teacherId, droppedGroups });
    const [{ isOver }, drop] = useDrop({
        accept: 'GROUP',
        drop: (item) => {
            if (onDrop) {  // Check if onDrop is defined
                onDrop(item, sectionPeriodId, teacherId);
            } else {
                console.error("Drop handler is undefined.");
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    });

    return (
        <td ref={drop} className={`drop-cell ${isOver ? 'drop-hover' : ''}`}>
            <div className="drop-area">
                {Array.isArray(droppedGroups) && droppedGroups.map((group) => (
                    <DraggableGroup
                        key={group.id}
                        group={group}
                        sectionPeriodId={sectionPeriodId}
                        teacherId={teacherId}
                        onDrop={onDrop}
                    />
                ))}
            </div>
        </td>
    );
};

const DraggableGroup = ({ group, sectionPeriodId, teacherId, onDrop }) => {
    console.log("Rendering DraggableGroup:", { group, sectionPeriodId, teacherId });
    const [{ isDragging }, drag] = useDrag({
        type: 'GROUP',
        item: { ...group, sectionPeriodId, teacherId },
        end: (item, monitor) => {
            if (!monitor.didDrop()) {
                if (onDrop) {
                    onDrop(item, null, item.teacherId);  // Removing from the cell if dropped outside
                } else {
                    console.error("onDrop handler is undefined.");
                }
            }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    return (
        <div
            ref={drag}
            className="group-item"
            style={{ opacity: isDragging ? 0.5 : 1 }}
        >
            {group.name}
        </div>
    );
};

const TrashDropZone = ({ onGroupRemove }) => {
    console.log("Rendering TrashDropZone");
    const [{ isOver }, drop] = useDrop({
        accept: "GROUP",
        drop: (item) => {
            console.log("Dropping item into trash zone:", item);
            if (onGroupRemove) {
                onGroupRemove(item, null, item.teacherId);
            } else {
                console.error("onGroupRemove handler is undefined.");
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    });

    return (
        <div ref={drop} className={`trash-zone ${isOver ? "highlight" : ""}`}>
            Remove
        </div>
    );
};

export default SectionPeriodGrid;
