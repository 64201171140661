import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

function EditCourse() {
    const { id } = useParams();
    const navigate = useNavigate();

    const [course, setCourse] = useState({ name: '', core_class_id: '', cte_class_id: '', grade_level_id: '' });
    const [coreClasses, setCoreClasses] = useState([]);
    const [cteClasses, setCteClasses] = useState([]);
    const [gradeLevels, setGradeLevels] = useState([]);

    useEffect(() => {
        axios.get(`/courses/api/courses/${id}/`)
            .then(response => setCourse(response.data))
            .catch(error => console.error('Error fetching course details:', error));

        axios.get('/courses/api/core_classes/')
            .then(response => setCoreClasses(response.data))
            .catch(error => console.error('Error fetching core classes:', error));

        axios.get('/courses/api/cte_classes/')
            .then(response => setCteClasses(response.data))
            .catch(error => console.error('Error fetching CTE classes:', error));

        axios.get('/courses/api/grade_levels/')
            .then(response => setGradeLevels(response.data))
            .catch(error => console.error('Error fetching grade levels:', error));
    }, [id]);

    const handleChange = (e) => {
        setCourse({ ...course, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        axios.put(`/courses/api/courses/${id}/`, course)
            .then(() => {
                alert('Course updated successfully!');
                navigate('/courses');
            })
            .catch(error => {
                console.error('Error updating course:', error);
                alert('Failed to update course. Please try again.');
            });
    };

    return (
        <div>
            <h1>Edit Course</h1>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Course Name:</label>
                    <input
                        type="text"
                        name="name"
                        value={course.name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div>
                    <label>Core Class:</label>
                    <select
                        name="core_class_id"
                        value={course.core_class_id || ''}
                        onChange={handleChange}
                    >
                        <option value="">Select Core Class</option>
                        {coreClasses.length === 0 ? (
                            <option value="">No Core Classes Available</option>
                        ) : (
                            coreClasses.map(core => (
                                <option key={core.id} value={core.id}>{core.name}</option>
                            ))
                        )}
                    </select>
                </div>
                <div>
                    <label>CTE Class:</label>
                    <select
                        name="cte_class_id"
                        value={course.cte_class_id || ''}
                        onChange={handleChange}
                    >
                        <option value="">Select CTE Class</option>
                        {cteClasses.length === 0 ? (
                            <option value="">No CTE Classes Available</option>
                        ) : (
                            cteClasses.map(cte => (
                                <option key={cte.id} value={cte.id}>{cte.name}</option>
                            ))
                        )}
                    </select>
                </div>
                <div>
                    <label>Grade Level:</label>
                    <select
                        name="grade_level_id"
                        value={course.grade_level_id || ''}
                        onChange={handleChange}
                        required
                    >
                        <option value="">Select Grade Level</option>
                        {gradeLevels.length === 0 ? (
                            <option value="">No Grade Levels Available</option>
                        ) : (
                            gradeLevels.map(grade => (
                                <option key={grade.id} value={grade.id}>{grade.grade}</option>
                            ))
                        )}
                    </select>
                </div>
                <button type="submit">Update Course</button>
            </form>
        </div>
    );
}

export default EditCourse;
