import React from 'react';
import { useDrop } from 'react-dnd';
import DraggableStudent from './DraggableStudent';

const DroppableGroup = ({ group, onDrop }) => {
    const [{ isOver }, drop] = useDrop(() => ({
        accept: 'student',
        drop: (item) => onDrop(item, group),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    }));

    return (
        <div ref={drop} className={`group-container ${isOver ? 'highlight-drop' : ''}`}>
            <h3>
                {group.name} <span className="group-count">({group.students.length})</span>
            </h3>
            <div className="group-students">
                {group.students.map(student => (
                    <DraggableStudent key={student.id} student={student} groupId={group.id} />
                ))}
            </div>
        </div>
    );
};

export default DroppableGroup;
