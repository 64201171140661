import React from 'react';

function LoginButton() {
  const handleLogin = () => {
    // Redirect user to Google OAuth login
    window.location.href = '/social/login/google-oauth2/';
  };

  return (
    <button onClick={handleLogin}>Login with Google</button>
  );
}

export default LoginButton;
