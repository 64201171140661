import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

function EditGroup() {
    const { id } = useParams();
    const [group, setGroup] = useState({ name: '', grade_level_id: '' });
    const [gradeLevels, setGradeLevels] = useState([]);

    useEffect(() => {
        axios.get(`/groups/api/edit/${id}/`)
            .then(response => setGroup(response.data))
            .catch(error => console.error('Error fetching group:', error));

        axios.get('/classes/api/grade-levels/')
            .then(response => setGradeLevels(response.data))
            .catch(error => console.error('Error fetching grade levels:', error));
    }, [id]);

    const handleChange = (e) => {
        setGroup({ ...group, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.put(`/groups/api/edit/${id}/`, group)
            .then(response => alert('Group updated successfully'))
            .catch(error => console.error('Error updating group:', error));
    };

    return (
        <form onSubmit={handleSubmit}>
            <input
                type="text"
                name="name"
                value={group.name}
                onChange={handleChange}
                placeholder="Group Name"
                required
            />
            <select
                name="grade_level_id"
                value={group.grade_level_id}
                onChange={handleChange}
                required
            >
                <option value="">Select Grade Level</option>
                {gradeLevels.map(grade => (
                    <option key={grade.id} value={grade.id}>{grade.grade}</option>
                ))}
            </select>
            <button type="submit">Update Group</button>
        </form>
    );
}

export default EditGroup;
