import React, { useState, useEffect } from 'react';
import axios from 'axios';

function CourseList() {
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        axios.get('/courses/api/list/')
            .then(response => {
                console.log('Courses Response:', response.data);
                if (Array.isArray(response.data)) {
                    setCourses(response.data);
                } else {
                    setError('Unexpected response format');
                    console.error('Unexpected response format:', response.data);
                }
            })
            .catch(error => {
                setError('Error fetching courses');
                console.error('Error fetching courses:', error);
            })
            .finally(() => setLoading(false));
    }, []);

    return (
        <div>
            <h1>Courses List</h1>
            <a href="/courses/add" className="btn btn-primary mb-3">Add New Course</a>
            {loading ? (
                <p>Loading courses...</p>
            ) : error ? (
                <p style={{ color: 'red' }}>{error}</p>
            ) : (
                courses.length > 0 ? (
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Course Name</th>
                                <th>Grade Level</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {courses.map(course => (
                                <tr key={course.id}>
                                    <td>{course.name}</td>
                                    {/* Check if grade_level and grade exist */}
                                    <td>{course.grade_level ? course.grade_level.grade : 'N/A'}</td>
                                    <td>
                                        <a href={`/courses/edit/${course.id}`} className="btn btn-sm btn-outline-secondary">Edit</a>
                                        <button className="btn btn-sm btn-outline-danger" onClick={() => deleteCourse(course.id)}>Delete</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>No courses available.</p>
                )
            )}
        </div>
    );
}

function deleteCourse(id) {
    if (window.confirm('Are you sure you want to delete this course?')) {
        axios.delete(`/courses/api/courses/${id}/`)
            .then(() => alert('Course deleted'))
            .catch(error => console.error('Error deleting course:', error));
    }
}

export default CourseList;
