import React from 'react';
import { useDrop } from 'react-dnd';
import DraggableStudentCTE from './DraggableStudentCTE';

const DroppableGroupCTE = ({ group, onDrop }) => {
    const [{ isOver }, drop] = useDrop(() => ({
        accept: 'student',
        drop: (item) => {
            console.log(`Dropping student ${item.id} into group ${group.id}`);
            onDrop(item, group);
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    }));

    return (
        <div ref={drop} className={`group-container ${isOver ? 'highlight-drop' : ''}`}>
            <div className="group-header">
                <h3>
                    {group.name} <span className="group-count">({group.students.length || 0})</span>
                </h3>
            </div>
            <div className="group-students">
                {group.students.length > 0 ? (
                    group.students.map((student) => (
                        <DraggableStudentCTE key={student.id} student={student} groupId={group.id} />
                    ))
                ) : (
                    <p>No students in this group.</p>
                )}
            </div>
        </div>
    );
};

export default DroppableGroupCTE;
