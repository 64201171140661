import React, { useEffect, useState } from 'react';
import axios from 'axios';

function SectionPeriodList({ blockSchedule, gradeLevel, refreshList }) {
    const [sectionPeriods, setSectionPeriods] = useState([]);
    const [editingPeriod, setEditingPeriod] = useState(null);
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [daysOfWeek, setDaysOfWeek] = useState([]);
    const [homeroom, setHomeroom] = useState(false);

    useEffect(() => {
        if (blockSchedule && gradeLevel) {
            axios
                .get(`/schedules/api/section-periods/get_with_associations/?block_schedule=${blockSchedule}&grade_level=${gradeLevel}`)
                .then(response => {
                    setSectionPeriods(response.data.map(sp => ({ ...sp, homeroom: sp.homeroom || false })));
                })
                .catch(error => console.error('Error fetching section periods:', error));
        }
    }, [blockSchedule, gradeLevel, refreshList]);

    const startEdit = (sectionPeriod) => {
        setEditingPeriod(sectionPeriod.id);
        setStartTime(sectionPeriod.start_time);
        setEndTime(sectionPeriod.end_time);
        setDaysOfWeek(sectionPeriod.days_of_week);
        setHomeroom(sectionPeriod.homeroom);
    };

    const handleDaysOfWeekChange = (day) => {
        if (daysOfWeek.includes(day)) {
            setDaysOfWeek(daysOfWeek.filter(d => d !== day));
        } else {
            setDaysOfWeek([...daysOfWeek, day]);
        }
    };

    const handleEditSubmit = (e, id) => {
        e.preventDefault();

        const updatedPeriod = {
            start_time: startTime,
            end_time: endTime,
            days_of_week: daysOfWeek,
            block_schedule: blockSchedule,
            grade_level: gradeLevel,
            homeroom: homeroom, // Include homeroom
        };

        axios
            .put(`/schedules/api/section-periods/${id}/`, updatedPeriod, {
                headers: {
                    'X-CSRFToken': getCSRFToken(),
                },
                withCredentials: true,
            })
            .then(() => {
                setEditingPeriod(null);
                setSectionPeriods(sectionPeriods.map(sp => (sp.id === id ? { ...sp, ...updatedPeriod } : sp)));
            })
            .catch(error => console.error('Error updating section period:', error));
    };

    const deleteSectionPeriod = (id) => {
        axios
            .delete(`/schedules/api/section-periods/${id}/`, {
                headers: {
                    'X-CSRFToken': getCSRFToken(),
                },
                withCredentials: true,
            })
            .then(() => {
                setSectionPeriods(sectionPeriods.filter(sp => sp.id !== id));
            })
            .catch(error => console.error('Error deleting section period:', error));
    };

    return (
        <div>
            <table>
                <thead>
                    <tr>
                        <th className="teacher-header">Days of Week</th>
                        <th className="teacher-header">Start Time</th>
                        <th className="teacher-header">End Time</th>
                        <th className="teacher-header">Homeroom</th> {/* New column */}
                        <th className="teacher-header">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {sectionPeriods.map(sp => (
                        <tr key={sp.id}>
                            <td className="time-slot">
                                {editingPeriod === sp.id ? (
                                    <div>
                                        {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'].map(day => (
                                            <label key={day}>
                                                <input
                                                    type="checkbox"
                                                    checked={daysOfWeek.includes(day)}
                                                    onChange={() => handleDaysOfWeekChange(day)}
                                                />
                                                {day}
                                            </label>
                                        ))}
                                    </div>
                                ) : Array.isArray(sp.days_of_week) && sp.days_of_week.length > 0
                                    ? sp.days_of_week.join(', ')
                                    : 'No days selected'}
                            </td>
                            <td className="time-slot">
                                {editingPeriod === sp.id ? (
                                    <input
                                        type="time"
                                        value={startTime}
                                        onChange={(e) => setStartTime(e.target.value)}
                                    />
                                ) : sp.start_time}
                            </td>
                            <td className="time-slot">
                                {editingPeriod === sp.id ? (
                                    <input
                                        type="time"
                                        value={endTime}
                                        onChange={(e) => setEndTime(e.target.value)}
                                    />
                                ) : sp.end_time}
                            </td>
                            <td className="time-slot"> {/* New Homeroom Column */}
                                {editingPeriod === sp.id ? (
                                    <input
                                        type="checkbox"
                                        checked={homeroom}
                                        onChange={(e) => setHomeroom(e.target.checked)}
                                    />
                                ) : sp.homeroom ? 'Yes' : 'No'}
                            </td>
                            <td className="time-slot">
                                {editingPeriod === sp.id ? (
                                    <button onClick={(e) => handleEditSubmit(e, sp.id)}>Save</button>
                                ) : (
                                    <>
                                        <button onClick={() => startEdit(sp)}>Edit</button>
                                        <button onClick={() => deleteSectionPeriod(sp.id)}>Delete</button>
                                    </>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

// Helper function to get CSRF token
function getCSRFToken() {
    const cookies = document.cookie.split(';');
    for (let cookie of cookies) {
        const [name, value] = cookie.trim().split('=');
        if (name === 'csrftoken') return value;
    }
    return null;
}

export default SectionPeriodList;
