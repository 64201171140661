import React, { useState } from "react";
import axios from "axios";
import TeacherSelection from "../Schedule/TeacherSelection";
import "./Groups.css";

function HomeroomGroup({ group }) {
    return (
        <div className="homeroom-group">
            <h3>{group.name}</h3>
            <p><strong>Grade Level:</strong> {group.grade_level}</p>
            <p><strong>Student Count:</strong> {group.student_count}</p> {/* Display student count */}
            <div className="group-students">
                {group.students.map((student) => (
                    <div key={student.id} className="group-student">
                        {student.first_name} {student.last_name}
                    </div>
                ))}
            </div>
        </div>
    );
}


function ViewHomerooms() {
    const [homeroomGroups, setHomeroomGroups] = useState([]);
    const [selectedTeachers, setSelectedTeachers] = useState([]);

    const fetchHomeroomGroups = (selectedTeachers) => {
    if (!selectedTeachers || selectedTeachers.length === 0) {
        alert("Please select at least one teacher before proceeding.");
        return;
    }

    // Format teachers query parameter
    const teacherParams = selectedTeachers
        .map((teacher) => `teachers[]=${teacher.id}`)
        .join("&");

    const url = `/api/groups/assign-homeroom/?${teacherParams}`;

    axios
        .get(url)
        .then((response) => {
            setHomeroomGroups(response.data.homeroom_groups || []);
        })
        .catch((error) => console.error("Error fetching homeroom groups:", error));
    };


    return (
        <div className="assign-homeroom">

            <h1>View Homeroom Groups</h1>

            <TeacherSelection
                onSelection={(teachers) => {
                    setSelectedTeachers(teachers);
                    fetchHomeroomGroups(teachers); // Fetch groups after teacher selection
                }}
                onContinue={() => {
                    fetchHomeroomGroups(selectedTeachers);
                }}
            />
            <div className="assign-homeroom-wrapper">
                <h2 className="homeroom-title">Homeroom Groups</h2>
                <div className="homeroom-groups-list">

                    {homeroomGroups.length > 0 ? (
                        homeroomGroups.map((group) => (
                            <HomeroomGroup key={group.id} group={group}/>
                        ))
                    ) : (
                        <p>No homeroom groups available for the selected teachers.</p>
                    )}
                </div>

            </div>
        </div>
    );
}

export default ViewHomerooms;
