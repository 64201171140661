// CourseHome.js
import React from 'react';
import { Link } from 'react-router-dom';
import './CourseHome.css'; // Import custom CSS styles

function CourseHome() {
  return (
    <div className="course-home-container">
      <h1 className="title">Course Management</h1>
      <div className="course-buttons">
        <Link to="/courses/add" className="btn btn-primary">Add Course</Link>
        <Link to="/courses/list" className="btn btn-info">View Courses</Link>
        {/* Replace ':id' with a valid course ID dynamically */}
        <Link to="/courses/edit/1" className="btn btn-warning">Edit Course</Link>
      </div>
    </div>
  );
}

export default CourseHome;
