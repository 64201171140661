import React, {useState} from 'react';
import axios from 'axios';
import {getCSRFToken} from "../utils";

const DuplicateSectionPeriods = ({blockSchedules, onClose}) => {
    const [sourceBlock, setSourceBlock] = useState('');
    const [targetBlock, setTargetBlock] = useState('');
    const [gradeLevel, setGradeLevel] = useState('');
    const [gradeLevels, setGradeLevels] = useState([]);

    // Fetch grade levels on mount
    React.useEffect(() => {
        axios.get('/courses/api/grade_levels/')
            .then((response) => setGradeLevels(response.data))
            .catch((error) => console.error('Error fetching grade levels:', error));
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post('/schedules/api/section-periods/duplicate/', {
            source_block: sourceBlock,
            target_block: targetBlock,
            grade_level: gradeLevel,
        }, {
            headers: {
                'X-CSRFToken': getCSRFToken(),
            },
        })
            .then(() => {
                alert('Section periods duplicated successfully!');
                onClose();
            })
            .catch((error) => alert('Error duplicating section periods: ' + error.message));
    };

    return (
        <div className="modal">
            <form onSubmit={handleSubmit}>
                <h2>Duplicate Section Periods</h2>
                <label>
                    Source Block Schedule:
                    <select value={sourceBlock} onChange={(e) => setSourceBlock(e.target.value)}>
                        <option value="">Select...</option>
                        {blockSchedules.map((block) => (
                            <option key={block.id} value={block.id}>{block.name}</option>
                        ))}
                    </select>
                </label>
                <label>
                    Target Block Schedule:
                    <select value={targetBlock} onChange={(e) => setTargetBlock(e.target.value)}>
                        <option value="">Select...</option>
                        {blockSchedules.map((block) => (
                            <option key={block.id} value={block.id}>{block.name}</option>
                        ))}
                    </select>
                </label>
                <label>
                    Grade Level:
                    <select value={gradeLevel} onChange={(e) => setGradeLevel(e.target.value)}>
                        <option value="">Select...</option>
                        {gradeLevels.map((level) => (
                            <option key={level.id} value={level.id}>{level.grade}</option>
                        ))}
                    </select>
                </label>
                <button type="submit">Duplicate</button>
                <button type="button" onClick={onClose}>Cancel</button>
            </form>
        </div>
    );
};

export default DuplicateSectionPeriods;
