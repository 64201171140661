import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './schedule.css';

const TeacherSelection = ({ onSelection, onContinue }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [teachers, setTeachers] = useState([]);
    const [selectedTeachers, setSelectedTeachers] = useState([]);

    // Fetch teachers based on the search query
    useEffect(() => {
        if (searchQuery.length > 2) {
            axios.get('/api/teachers/search/', { params: { search: searchQuery } })
                .then(response => setTeachers(response.data))
                .catch(error => console.error('Error searching teachers:', error));
        } else {
            setTeachers([]);
        }
    }, [searchQuery]);

    // Toggle teacher selection
    const handleTeacherToggle = (teacher) => {
        setSelectedTeachers(prev =>
            prev.some(t => t.id === teacher.id)
                ? prev.filter(t => t.id !== teacher.id)
                : [...prev, teacher]
        );
    };

    const handleContinueClick = () => {
        if (selectedTeachers.length > 0) {
            onSelection(selectedTeachers);
            onContinue();
        } else {
            alert("Please select at least one teacher.");
        }
    };

    const removeSelectedTeacher = (teacherId) => {
        setSelectedTeachers(prev => prev.filter(t => t.id !== teacherId));
    };

    return (
        <div>
            <h3>Select Teachers</h3>
            <input
                type="text"
                placeholder="Search for teachers"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
            />

            <div className="selected-teachers">
                <h4>Selected Teachers</h4>
                {selectedTeachers.map(teacher => (
                    <div key={teacher.id} className="selected-teacher-item">
                        {teacher.first_name} {teacher.last_name}
                        <button onClick={() => removeSelectedTeacher(teacher.id)}>Remove</button>
                    </div>
                ))}
            </div>

            <div className="teacher-selection-container">
                <h4>Search Results</h4>
                {teachers.map(teacher => (
                    <div key={teacher.id} className="teacher-item">
                        <input
                            type="checkbox"
                            checked={selectedTeachers.some(t => t.id === teacher.id)}
                            onChange={() => handleTeacherToggle(teacher)}
                        />
                        <label>{teacher.first_name} {teacher.last_name}</label>
                    </div>
                ))}
            </div>

            <button onClick={handleContinueClick} disabled={selectedTeachers.length === 0}>
                Continue
            </button>
        </div>
    );
};

export default TeacherSelection;
