// frontend/src/components/Schedules/BlockScheduleList.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';

function BlockScheduleList() {
    const [blockSchedules, setBlockSchedules] = useState([]);

   useEffect(() => {
    axios.get('/schedules/api/block-schedules/')
        .then(response => {
            // Ensure the data is an array, or default to an empty array
            setBlockSchedules(Array.isArray(response.data) ? response.data : []);
        })
        .catch(error => console.error('Error fetching block schedules:', error));
}, []);

    return (
    <div>
        <h1>Block Schedules</h1>
        <ul>
            {Array.isArray(blockSchedules) && blockSchedules.length > 0 ? (
                blockSchedules.map(bs => (
                    <li key={bs.id}>
                        {bs.name} ({bs.start_date} - {bs.end_date})
                    </li>
                ))
            ) : (
                <p>No block schedules available.</p>
            )}
        </ul>
    </div>
);
}

export default BlockScheduleList;
