import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

const EditStudent = () => {
    const { studentId } = useParams();
    const navigate = useNavigate();
    const [cookies] = useCookies(['csrftoken']);
    const [student, setStudent] = useState(null);
    const [isActive, setIsActive] = useState(false);
    const [currentHomeroomTeachers, setCurrentHomeroomTeachers] = useState([]);
    const [searchResults, setSearchResults] = useState([]);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [searchQuery, setSearchQuery] = useState('');

    // Fetch student details on load
    useEffect(() => {
        axios
            .get(`/students/api/details/${studentId}/`)
            .then((response) => {
                const data = response.data;
                setStudent(data);
                setIsActive(data.is_active);
                setFirstName(data.first_name);
                setLastName(data.last_name);
                setEmail(data.email);
                setCurrentHomeroomTeachers(data.homeroom_teachers || []);
            })
            .catch((error) => console.error('Error fetching student details:', error));
    }, [studentId]);

    // Search for teachers
    const handleSearch = (query) => {
        setSearchQuery(query);
        if (query.length > 2) {
            axios
                .get('/api/teachers/search/', { params: { search: query } })
                .then((response) => setSearchResults(response.data))
                .catch((error) => console.error('Error fetching teachers:', error));
        } else {
            setSearchResults([]);
        }
    };

    const handleSave = () => {
        const payload = {
            first_name: firstName,
            last_name: lastName,
            email: email,
            is_active: isActive,
            homeroom_teacher_ids: currentHomeroomTeachers.map((teacher) => teacher.id),
        };

        axios
            .patch(`/students/api/update/${studentId}/`, payload, {
                headers: { 'X-CSRFToken': cookies.csrftoken },
            })
            .then((response) => {
                console.log('Student updated successfully:', response.data);
                navigate(-1); // Go back to the previous page
            })
            .catch((error) => alert('Error updating student: ' + error.message));
    };

    const addHomeroomTeacher = (teacher) => {
        if (!currentHomeroomTeachers.find((t) => t.id === teacher.id)) {
            setCurrentHomeroomTeachers([...currentHomeroomTeachers, teacher]);
        }
    };

    const removeHomeroomTeacher = (teacherId) => {
        setCurrentHomeroomTeachers(currentHomeroomTeachers.filter((t) => t.id !== teacherId));
    };

    if (!student) return <p>Loading student data...</p>;

    return (
        <div>
            <h2>Edit Student</h2>
            <label>
                First Name:
                <input
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                />
            </label>
            <label>
                Last Name:
                <input
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                />
            </label>
            <label>
                Email:
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </label>
            <label>
                <input
                    type="checkbox"
                    checked={isActive}
                    onChange={() => setIsActive(!isActive)}
                />
                Active Status
            </label>

            <div>
                <h3>Current Homeroom Teachers</h3>
                {currentHomeroomTeachers.map((teacher) => (
                    <div key={teacher.id}>
                        {teacher.first_name} {teacher.last_name}
                        <button onClick={() => removeHomeroomTeacher(teacher.id)}>Remove</button>
                    </div>
                ))}
            </div>

            <div>
                <h3>Add Homeroom Teacher</h3>
                <input
                    type="text"
                    placeholder="Search for teachers"
                    value={searchQuery}
                    onChange={(e) => handleSearch(e.target.value)}
                />
                {searchResults.map((teacher) => (
                    <div key={teacher.id}>
                        {teacher.first_name} {teacher.last_name}
                        <button onClick={() => addHomeroomTeacher(teacher)}>Add</button>
                    </div>
                ))}
            </div>

            <button onClick={handleSave}>Save Changes</button>
            <button onClick={() => navigate(-1)}>Cancel</button>
        </div>
    );
};

export default EditStudent;
