import React, { useState } from 'react';
import axios from 'axios';

const RemoveGroup = ({ groupId, groupName, onRemove, cteTeacher }) => {
    const [isConfirming, setIsConfirming] = useState(false);

    const handleRemove = async () => {
        try {
            const response = await axios.delete(`/api/groups/${groupId}/`, {
                headers: { 'X-CSRFToken': document.cookie.match(/csrftoken=([^;]*)/)?.[1] },
                params: cteTeacher ? { cte_teacher: cteTeacher } : {}, // Include cte_teacher if provided
            });

            if (response.status === 204) {
                console.log('Group deleted with no content response.');
                onRemove(groupId, []); // Assume no students are returned
            } else if (response.status === 200 && response.data?.success) {
                onRemove(groupId, response.data.removedStudents || []);
            }
        } catch (error) {
            console.error('Error deleting group:', error.message);
            alert('An error occurred while deleting the group.');
        } finally {
            setIsConfirming(false);
        }
    };

    return (
        <div className="remove-group">
            {!isConfirming ? (
                <button
                    className="remove-group-btn"
                    onClick={() => setIsConfirming(true)}
                >
                    Remove
                </button>
            ) : (
                <div className="confirm-remove">
                    <p>Are you sure you want to delete "{groupName}"? This will move all students in the group to unassigned.</p>
                    <button className="confirm-btn" onClick={handleRemove}>
                        Yes, Delete
                    </button>
                    <button
                        className="cancel-btn"
                        onClick={() => setIsConfirming(false)}
                    >
                        Cancel
                    </button>
                </div>
            )}
        </div>
    );
};

export default RemoveGroup;
