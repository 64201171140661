import React, {useEffect, useState} from 'react';
import './attendance.css';

function getCsrfToken() {
    const csrfCookie = document.cookie
        .split('; ')
        .find(row => row.startsWith('csrftoken='));
    return csrfCookie ? csrfCookie.split('=')[1] : '';
}

const TakeAttendance = ({sectionPeriod, closeAttendance, selectedDate}) => {
    const [attendance, setAttendance] = useState({});
    const [loading, setLoading] = useState(true);
    const [disableSubmit, setDisableSubmit] = useState(false);

    const excuseOptions = [
        {value: 'AEX', label: 'Absent Excused'},
        {value: 'AUX', label: 'Absent Unexcused'},
        {value: 'TEX', label: 'Tardy Excused'},
        {value: 'TUX', label: 'Tardy Unexcused'},
    ];

    useEffect(() => {
        if (!sectionPeriod || !selectedDate) return;

        setLoading(true);

        const teacherId = sectionPeriod.teachers[0]?.id;
        const url = `/attendance/submit/?section_period_id=${sectionPeriod.id}&date=${selectedDate}&teacher_id=${teacherId}`;

        fetch(url)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Failed to fetch attendance data.');
                }
                return response.json();
            })
            .then(data => {
                console.log("Fetched Attendance Data:", data);

                if (!data.success) {
                    console.error("Error loading attendance data:", data.message);
                    setLoading(false);
                    return;
                }

                if (!Array.isArray(data.attendance)) {
                    console.error("Attendance data is not an array:", data.attendance);
                    setLoading(false);
                    return;
                }

                if (data.message?.includes("holiday")) {
                    setDisableSubmit(true); // Disable submit if the date is a holiday
                } else {
                    setDisableSubmit(false);
                }

                const existingAttendance = data.attendance.reduce((acc, record) => {
                    const studentId = record.student_id || record.membership?.student?.id; // Handle both cases
                    if (!studentId) {
                        console.error("Missing student_id in record:", record); // Debug missing IDs
                    } else {
                        acc[studentId] = {
                            status: record.status || 'P',
                            excuse: record.excuse || '',
                            comment: record.comment || '',
                        };
                    }
                    return acc;
                }, {});

                console.log("Processed Existing Attendance State:", existingAttendance);
                setAttendance(existingAttendance);
                setLoading(false);
            })
            .catch(error => {
                console.error("Error fetching attendance records:", error);
                setLoading(false);
            });
    }, [sectionPeriod, selectedDate]);


    const handleAttendanceChange = (studentId, status) => {
        setAttendance(prev => ({
            ...prev,
            [studentId]: {
                ...prev[studentId],
                status,
            },
        }));
    };

    const handleInputChange = (studentId, field, value) => {
        setAttendance(prev => ({
            ...prev,
            [studentId]: {...prev[studentId], [field]: value},
        }));
    };

    const submitAttendance = () => {
        const teacherId = sectionPeriod.teachers[0]?.id;
        const data = {
            section_period_id: sectionPeriod.id,
            attendance,
            date: selectedDate,
            teacher_id: teacherId,
        };

        fetch('/attendance/submit/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRFToken': getCsrfToken(),
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    alert('Attendance submitted successfully!');
                    closeAttendance();
                } else {
                    alert('Failed to submit attendance.');
                }
            })
            .catch(error => {
                console.error('Error submitting attendance:', error);
                alert('Error submitting attendance.');
            });
    };

    if (loading) {
        return <p>Loading attendance data...</p>;
    }

    return (
        <div className="attendance-container">
            <h3>Take Attendance</h3>
            {sectionPeriod.groups.map(group => (
                <div key={group.id} className="group-container">
                    <h5>Group: {group.name}</h5>
                    {group.students.map(student => (
                        <div key={student.id} className="student-item">
                            <div className="student-row">
                                <span className="student-name">{student.name}</span>
                                <div className="attendance-options">
                                    <label
                                        className={`attendance-option ${attendance[student.id]?.status === 'P' ? 'selected' : ''}`}
                                        onClick={() => handleAttendanceChange(student.id, 'P')}
                                    >
                                        P
                                    </label>
                                    <label
                                        className={`attendance-option ${attendance[student.id]?.status === 'A' ? 'selected' : ''}`}
                                        onClick={() => handleAttendanceChange(student.id, 'A')}
                                    >
                                        A
                                    </label>
                                    <label
                                        className={`attendance-option ${attendance[student.id]?.status === 'T' ? 'selected' : ''}`}
                                        onClick={() => handleAttendanceChange(student.id, 'T')}
                                    >
                                        T
                                    </label>
                                </div>
                                {(attendance[student.id]?.status === 'A' || attendance[student.id]?.status === 'T') && (
                                    <>
                                        <select
                                            className="excuse-select"
                                            value={attendance[student.id]?.excuse || ''}
                                            onChange={e => handleInputChange(student.id, 'excuse', e.target.value)}
                                        >
                                            <option value="">Select Excuse</option>
                                            {excuseOptions.map(option => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </select>
                                        <input
                                            type="text"
                                            className="comment-input"
                                            placeholder="Comment (optional)"
                                            value={attendance[student.id]?.comment || ''}
                                            onChange={e => handleInputChange(student.id, 'comment', e.target.value)}
                                        />
                                    </>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            ))}
            <button
                onClick={submitAttendance}
                className="attendance-button"
                disabled={disableSubmit}
            >
                Submit Attendance
            </button>
            <button onClick={closeAttendance} className="attendance-button">Back</button>
            {disableSubmit && (
                <p style={{color: 'red'}}>
                    Attendance cannot be submitted for holidays.
                </p>
            )}
        </div>
    );
};

export default TakeAttendance;
