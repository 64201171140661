import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

function EditSectionPeriod() {
    const { id } = useParams();
    const [sectionPeriod, setSectionPeriod] = useState(null);
    const [formData, setFormData] = useState({
        start_time: '',
        end_time: '',
        grade_level: '',
        days_of_week: [],
        homeroom: false, // Add homeroom field

    });

    useEffect(() => {
        // Fetch section period data by ID
        axios.get(`/schedules/api/edit-section-period/${id}/`)
            .then(response => {
                setSectionPeriod(response.data);
                setFormData({
                    start_time: response.data.start_time,
                    end_time: response.data.end_time,
                    grade_level: response.data.grade_level.id,
                    days_of_week: response.data.days_of_week,
                    homeroom: response.data.homeroom, // Populate homeroom
                });
            })
            .catch(error => console.error('Error fetching section period:', error));
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.put(`/schedules/api/edit-section-period/${id}/`, formData)
            .then(response => {
                alert('Section Period updated successfully!');
            })
            .catch(error => {
                console.error('Error updating section period:', error);
                alert('Failed to update Section Period.');
            });
    };

    if (!sectionPeriod) return <div>Loading...</div>;

    return (
        <form onSubmit={handleSubmit}>
            <h2>Edit Section Period</h2>
            <label>
                Start Time:
                <input type="time" name="start_time" value={formData.start_time} onChange={handleChange}/>
            </label>
            <label>
                End Time:
                <input type="time" name="end_time" value={formData.end_time} onChange={handleChange}/>
            </label>
            <label>
                Grade Level:
                <input type="text" name="grade_level" value={formData.grade_level} onChange={handleChange}/>
            </label>
            <label>
                Days of Week:
                <input type="text" name="days_of_week" value={formData.days_of_week} onChange={handleChange}/>
            </label>
            <label>
                Homeroom:
                <input type="checkbox" name="homeroom" checked={formData.homeroom}
                       onChange={(e) => setFormData({...formData, homeroom: e.target.checked})}/>
            </label>
            <button type="submit">Save</button>
        </form>
    );
}

export default EditSectionPeriod;
