// frontend/src/components/Schedules/ScheduleHome.js
import React from 'react';
import { Link } from 'react-router-dom';

function ScheduleHome() {
    return (
        <div>
            <h1>Schedule Management</h1>
            <div className="feature-links">
                <Link to="/schedules/create-section" className="feature-button">Manage Section Periods</Link>
                {/*<Link to="/schedules/view-sections" className="feature-button">View Section Periods</Link>*/}

                <Link to="/schedules/block-schedules" className="feature-button">View Block Schedules</Link>

                <Link to="/schedules/tool" className="feature-button">Open Scheduling Tool</Link>
            </div>
        </div>
    );
}

export default ScheduleHome;
