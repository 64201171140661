import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TeacherSelection from './TeacherSelection';
import SectionPeriodGrid from './SectionPeriodGrid';
import DraggableGroups from './DraggableGroups';

function getCSRFToken() {
    const cookie = document.cookie.split(';').find(item => item.trim().startsWith('csrftoken='));
    return cookie ? decodeURIComponent(cookie.split('=')[1]) : '';
}

const ScheduleTool = () => {
    const [gradeLevels, setGradeLevels] = useState([]);
    const [selectedGradeLevel, setSelectedGradeLevel] = useState(null);
    const [blockSchedules, setBlockSchedules] = useState([]);
    const [selectedBlockSchedule, setSelectedBlockSchedule] = useState(null);
    const [selectedTeachers, setSelectedTeachers] = useState([]);
    const [sectionPeriods, setSectionPeriods] = useState([]);
    const [preloadedAssociations, setPreloadedAssociations] = useState([]);
    const [proceedToScheduling, setProceedToScheduling] = useState(false);
    const [hasExistingTeachers, setHasExistingTeachers] = useState(false);
    const [droppedGroups, setDroppedGroups] = useState({});

    // Fetch grade levels
    useEffect(() => {
        axios.get('/courses/api/grade_levels/')
            .then(response => setGradeLevels(response.data))
            .catch(error => console.error('Error fetching grade levels:', error));
    }, []);

    // Fetch block schedules for the selected grade level
    useEffect(() => {
        if (selectedGradeLevel) {
            axios.get(`/schedules/api/block-schedules/?grade_level=${selectedGradeLevel}`)
                .then(response => setBlockSchedules(response.data))
                .catch(error => console.error('Error fetching block schedules:', error));
        }
    }, [selectedGradeLevel]);

    // Fetch section periods and teachers
    useEffect(() => {
        if (selectedGradeLevel && selectedBlockSchedule) {
            console.log("Fetching section periods and teachers for:", { selectedGradeLevel, selectedBlockSchedule });
            axios.get(`/schedules/api/section-periods/get_with_associations/?block_schedule=${selectedBlockSchedule}&grade_level=${selectedGradeLevel}`)
                .then(response => {
                    const sectionPeriodsData = response.data;
                    console.log("Section Periods Response:", sectionPeriodsData);

                    if (Array.isArray(sectionPeriodsData) && sectionPeriodsData.length > 0) {
                        setPreloadedAssociations(sectionPeriodsData);
                        setSectionPeriods(sectionPeriodsData);

                        console.log("Inspecting teachers in SectionPeriods:", sectionPeriodsData.map(sp => sp.teachers));

                        const uniqueTeachers = sectionPeriodsData.reduce((acc, period) => {
                            if (period.teachers && period.teachers.length > 0) {
                                period.teachers.forEach(teacher => {
                                    if (!acc.some(t => t.id === teacher.id)) {
                                        acc.push({ ...teacher, name: teacher.last_name });
                                    }
                                });
                            }
                            return acc;
                        }, []);

                        if (uniqueTeachers.length > 0) {
                            console.log("Populated teachers:", uniqueTeachers);
                            setSelectedTeachers(uniqueTeachers);
                            setHasExistingTeachers(true);
                        } else {
                            console.warn("No teachers found in SectionPeriods; requiring manual selection.");
                            setHasExistingTeachers(false);
                        }

                        const initialDroppedGroups = sectionPeriodsData.reduce((acc, period) => {
                            if (period.teachers) {
                                period.teachers.forEach(teacher => {
                                    const key = `${period.id}-${teacher.id}`;
                                    acc[key] = teacher.groups || [];
                                });
                            }
                            return acc;
                        }, {});
                        setDroppedGroups(initialDroppedGroups);
                        console.log("Preloaded Dropped Groups:", initialDroppedGroups);
                    } else {
                        console.warn("No section periods found in response.");
                        setHasExistingTeachers(false);
                    }
                })
                .catch(error => console.error('Error fetching associations:', error));
        }
    }, [selectedGradeLevel, selectedBlockSchedule]);

    const fetchHomeroomGroups = () => {
        const teacherIds = selectedTeachers.map(teacher => teacher.id);
        axios
            .get('/schedules/api/section-periods/get_homeroom_groups/', {
                params: {
                    grade_level: selectedGradeLevel,
                    teachers: teacherIds,
                },
            })
            .then(response => {
                console.log("Fetched Homeroom Groups:", response.data);
                setDroppedGroups((prevGroups) => {
                    const updatedGroups = { ...prevGroups };
                    response.data.forEach(group => {
                        sectionPeriods.forEach(period => {
                            if (period.homeroom && group.teacher_id === period.teacher_id) {
                                const key = `${period.id}-${group.teacher_id}`;
                                updatedGroups[key] = [group];
                            }
                        });
                    });
                    return updatedGroups;
                });
            })
            .catch(error => console.error("Error fetching homeroom groups:", error));
    };

    const handleGradeLevelChange = (e) => {
        const gradeLevelId = e.target.value;
        setSelectedGradeLevel(gradeLevelId);
        setProceedToScheduling(false);
        setSelectedBlockSchedule(null);
        setSectionPeriods([]);
        setHasExistingTeachers(false);
        setSelectedTeachers([]);
        console.log("Grade Level Changed:", gradeLevelId);
    };

    const handleBlockScheduleChange = (event) => {
        const blockScheduleId = event.target.value;
        setSelectedBlockSchedule(blockScheduleId);
        setProceedToScheduling(false);
        setHasExistingTeachers(false);
        console.log("Block Schedule Changed:", blockScheduleId);
    };

    const handleTeacherSelection = (selected) => {
        console.log("Manually selected teachers:", selected);
        setSelectedTeachers(selected);
    };

    const handleContinue = () => {
        console.log("Proceeding to Scheduling...");
        setProceedToScheduling(true);
        fetchHomeroomGroups(); // Fetch and assign homeroom groups
    };

    const handleGroupDrop = async (group, sectionPeriodId, teacherId) => {
        const csrfToken = getCSRFToken();

        if (sectionPeriodId === null) {
            console.log("Removing group with payload:", { group_ids: [group.id], teacher_id: teacherId, section_period_id: group.sectionPeriodId });
            try {
                await axios.post(
                    `/schedules/api/section-periods/${group.sectionPeriodId}/remove_group/`,
                    { group_ids: [group.id], teacher_id: teacherId, section_period_id: group.sectionPeriodId },
                    { headers: { "X-CSRFToken": csrfToken } }
                );
                console.log("Group removed successfully.");
                setDroppedGroups(prev => {
                    const updated = { ...prev };
                    const key = `${group.sectionPeriodId}-${teacherId}`;
                    updated[key] = (updated[key] || []).filter(g => g.id !== group.id);
                    return updated;
                });
            } catch (error) {
                console.error("Error removing group:", error);
            }
        } else {
            console.log("Assigning group with payload:", { group_ids: [group.id], teacher_id: teacherId });
            try {
                const response = await axios.post(
                    `/schedules/api/section-periods/${sectionPeriodId}/assign_groups/`,
                    { group_ids: [group.id], teacher_id: teacherId },
                    { headers: { "X-CSRFToken": csrfToken } }
                );
                console.log("Group assigned:", response.data);
                setDroppedGroups(prev => ({
                    ...prev,
                    [`${sectionPeriodId}-${teacherId}`]: [
                        ...(prev[`${sectionPeriodId}-${teacherId}`] || []),
                        group,
                    ],
                }));
            } catch (error) {
                console.error("Error assigning group:", error);
            }
        }
    };

    return (
        <div className="schedule-tool">
            <h2>Scheduling Tool</h2>
            <label>
                Select Grade Level:
                <select onChange={handleGradeLevelChange} value={selectedGradeLevel || ""}>
                    <option value="">--Select--</option>
                    {gradeLevels.map(grade => (
                        <option key={grade.id} value={grade.id}>{grade.grade}</option>
                    ))}
                </select>
            </label>

            {selectedGradeLevel && (
                <label>
                    Select Block Schedule:
                    <select onChange={handleBlockScheduleChange} value={selectedBlockSchedule || ""}>
                        <option value="">--Select--</option>
                        {blockSchedules.map(schedule => (
                            <option key={schedule.id} value={schedule.id}>{schedule.name}</option>
                        ))}
                    </select>
                </label>
            )}

            {selectedBlockSchedule && !proceedToScheduling && !hasExistingTeachers && (
                <TeacherSelection onSelection={handleTeacherSelection} onContinue={handleContinue} />
            )}

            {hasExistingTeachers && !proceedToScheduling && (
                <div>
                    <p>Existing teachers are associated with this schedule. Do you want to:</p>
                    <button onClick={() => setProceedToScheduling(true)}>Proceed with Existing Teachers</button>
                    <button onClick={() => setHasExistingTeachers(false)}>Modify Teacher Selection</button>
                </div>
            )}

            {proceedToScheduling && (
                <>
                    <DraggableGroups gradeLevelId={selectedGradeLevel} />
                    <SectionPeriodGrid
                        teachers={selectedTeachers}
                        sectionPeriods={sectionPeriods}
                        preloadedAssociations={preloadedAssociations}
                        droppedGroups={droppedGroups}
                        onGroupDrop={handleGroupDrop}
                    />
                </>
            )}
        </div>
    );
};

export default ScheduleTool;
