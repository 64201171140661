import React, {useEffect, useState} from 'react';
import axios from 'axios';
import TakeAttendance from './TakeAttendance';
import './attendance.css';

const AttendanceHome = () => {
    const [sectionPeriods, setSectionPeriods] = useState([]);
    const [teachers, setTeachers] = useState([]);
    const [selectedTeacher, setSelectedTeacher] = useState(null);
    const [selectedPeriod, setSelectedPeriod] = useState(null);
    const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]); // Default to today
    const [isAdmin, setIsAdmin] = useState(false);
    const [loading, setLoading] = useState(true); // Loading state for better UX
    const [message, setMessage] = useState(''); // State to store the backend message

    useEffect(() => {
        axios.get('/api/current-user/')
            .then(response => {
                // Use `is_superuser` to determine admin status
                const isAdmin = response.data.is_superuser || false;
                setIsAdmin(isAdmin);

                // Fetch data based on admin status
                if (isAdmin) {
                    fetchTeachers();
                } else {
                    fetchSectionPeriods();
                }
            })
            .catch(error => {
                console.error('Error fetching current user:', error);
                setLoading(false);
            });
    }, [selectedDate]);

    const fetchTeachers = () => {
        setLoading(true);
        axios.get('/api/teachers/all/')
            .then(response => {
                console.log("Fetched Teachers Data:", response.data);
                setTeachers(Array.isArray(response.data) ? response.data : []);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching teachers:', error);
                setTeachers([]); // Fallback to empty array
                setLoading(false);
            });
    };

    const fetchSectionPeriods = (teacherId = null) => {
        setLoading(true);
        const url = teacherId
            ? `/attendance/section-periods/?date=${selectedDate}&teacher_id=${teacherId}`
            : `/attendance/section-periods/?date=${selectedDate}`;
        axios.get(url)
            .then(response => {
                console.log("Fetched Section Periods Data:", response.data);
                if (response.data.message) {
                    setMessage(response.data.message); // Set the message from the backend
                    setSectionPeriods([]); // Clear periods if no section periods are available
                    setLoading(false);
                    return;
                }
                setMessage(''); // Clear any previous message
                const periods = Array.isArray(response.data.section_periods) ? response.data.section_periods : [];
                // Filter SectionPeriods for the selected teacher if `teacherId` is provided
                const filteredPeriods = teacherId
                    ? periods.filter(sp => sp.teachers.some(t => t.id === parseInt(teacherId)))
                    : periods;
                console.log("Filtered Section Periods:", filteredPeriods);
                setSectionPeriods(filteredPeriods);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching section periods:', error);
                setSectionPeriods([]);
                setLoading(false);
            });
    };

    const handleTeacherChange = (e) => {
        const teacherId = e.target.value;
        setSelectedTeacher(teacherId);
        fetchSectionPeriods(teacherId);
    };

    const handlePeriodChange = (e) => {
        const periodId = e.target.value;
        const period = sectionPeriods.find(sp => sp.id === parseInt(periodId));
        setSelectedPeriod(period || null); // Fallback to null if not found
    };

    const handleDateChange = (e) => {
        const newDate = e.target.value;
        setSelectedDate(newDate);
        setSelectedPeriod(null); // Reset selected period when date changes
    };

    return (
        <div className="attendance-container">
            <h2 className="attendance-header">Section Periods</h2>

            {/* Date Picker */}
            <div className="date-picker-container">
                <label htmlFor="attendance-date">Select Date:</label>
                <input
                    type="date"
                    id="attendance-date"
                    value={selectedDate}
                    onChange={handleDateChange}
                    className="date-picker"
                />
            </div>

            {isAdmin && (
                <div className="teacher-select">
                    <label htmlFor="teacher">Select Teacher:</label>
                    <select id="teacher" onChange={handleTeacherChange} value={selectedTeacher || ''}>
                        <option value="">All Teachers</option>
                        {teachers.map(teacher => (
                            <option key={teacher.id} value={teacher.id}>
                                {teacher.last_name}
                            </option>
                        ))}
                    </select>
                </div>
            )}

            {loading ? (
                <p>Loading...</p>
            ) : (
                <div className="section-period-select">
                    <label htmlFor="sectionPeriod">Select Section Period:</label>
                    {sectionPeriods.length > 0 ? (
                        <select
                            id="sectionPeriod"
                            onChange={handlePeriodChange}
                            value={selectedPeriod ? selectedPeriod.id : ''}
                        >
                            <option value="">Select a period</option>
                            {sectionPeriods.map(sp => (
                                <option key={sp.id} value={sp.id}>
                                    {sp.start_time} - {sp.end_time} | Teacher:{" "}
                                    {sp.teachers.map(t => t.last_name).join(", ")}
                                </option>
                            ))}
                        </select>
                    ) : (
                        <p>{message || "No section periods available for the selected date."}</p>
                    )}
                    {message.includes("holiday") && (
                        <p style={{color: 'red'}}>Attendance cannot be taken on holidays.</p>

                    )}
                </div>
            )
            }

            {!message.includes("holiday") && selectedPeriod ? (
                <TakeAttendance
                    sectionPeriod={selectedPeriod}
                    closeAttendance={() => setSelectedPeriod(null)}
                    selectedDate={selectedDate}
                />
            ) : (
                <p>{message || "Select a section period to take attendance."}</p>
            )}

        </div>
    )
        ;
};

export default AttendanceHome;
