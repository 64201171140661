// frontend/src/components/Schedules/ViewSectionPeriods.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';

function ViewSectionPeriods() {
    const [sectionPeriods, setSectionPeriods] = useState([]);

    useEffect(() => {
        axios.get('/schedules/api/section-periods/')
            .then(response => setSectionPeriods(response.data))
            .catch(error => console.error('Error fetching section periods:', error));
    }, []);

    return (
        <div>
            <h2>Section Periods</h2>
            <a href="/add-section-period">Add New Section Period</a>
            <table>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Start Time</th>
                        <th>End Time</th>
                        <th>Grade Level</th>
                        <th>Homeroom</th> {/* Add Homeroom column */}
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {sectionPeriods.map(sp => (
                        <tr key={sp.id}>
                            <td>{sp.name}</td>
                            <td>{sp.start_time}</td>
                            <td>{sp.end_time}</td>
                            <td>{sp.grade_level.grade}</td>
                            <td>{sp.homeroom ? 'Yes' : 'No'}</td> {/* Display Homeroom */}
                            <td>
                                <a href={`/edit-section-period/${sp.id}`}>Edit</a>
                                <a href={`/delete-section-period/${sp.id}`}>Delete</a>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default ViewSectionPeriods;
