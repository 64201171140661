// src/components/Schedule/AssignGroups.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AssignGroups = ({ sectionPeriodId }) => {
    const [groups, setGroups] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);

    useEffect(() => {
        // Fetch the list of groups to assign to the section period
        axios.get('/api/groups/api/list/')
            .then((response) => {
                setGroups(response.data);
            })
            .catch((error) => console.error('Error fetching groups:', error));
    }, []);

    const handleSubmit = (e) => {
        e.preventDefault();

        axios.post(`/api/section-periods/${sectionPeriodId}/assign-groups/`, {
            group_ids: selectedGroups,
        }).then((response) => {
            alert('Groups assigned successfully!');
        }).catch((error) => {
            console.error('There was an error assigning groups:', error);
        });
    };

    const handleGroupChange = (e) => {
        const value = Array.from(e.target.selectedOptions, option => option.value);
        setSelectedGroups(value);
    };

    return (
        <div>
            <h2>Assign Groups to Section Period</h2>
            <form onSubmit={handleSubmit}>
                <label>Select Groups:</label>
                <select multiple onChange={handleGroupChange}>
                    {groups.map(group => (
                        <option key={group.id} value={group.id}>{group.name}</option>
                    ))}
                </select>
                <button type="submit">Assign Groups</button>
            </form>
        </div>
    );
};

export default AssignGroups;
