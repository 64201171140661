// src/components/Students/StudentDashboard.js
import React from 'react';

const StudentDashboard = () => {
  return (
    <div>
      <h1>Student Dashboard</h1>
      <p>Welcome to your dashboard. You can view your schedule, groups, and assignments here.</p>
      {/* Add links or sections for student-specific actions */}
    </div>
  );
};

export default StudentDashboard;
