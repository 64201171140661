import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';

const StudentSchedule = () => {
    const { studentId } = useParams();
    const [events, setEvents] = useState([]);
    const [student, setStudent] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get(`/students/api/schedule/${studentId}/`)
            .then(response => {
                const studentData = response.data.student;
                const scheduleData = response.data.schedule;

                // Map schedule data to recurring events
                const calendarEvents = scheduleData.map(period => {
                    return {
                        title: `${period.teacher || ''} - ${period.group || ''}`, // Show teacher first
                        startTime: period.start_time,
                        endTime: period.end_time,
                        daysOfWeek: period.days_of_week.map(day => {
                            const dayMapping = {
                                Monday: 1,
                                Tuesday: 2,
                                Wednesday: 3,
                                Thursday: 4,
                            };
                            return dayMapping[day];
                        }),
                        startRecur: period.block_start, // Block start from backend
                        endRecur: period.block_end,   // Block end from backend
                    };
                });

                setStudent(studentData);
                setEvents(calendarEvents);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching student schedule:', error);
                setLoading(false);
            });
    }, [studentId]);

    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <div className="schedule-container">
            <h2>Schedule for {student?.name}</h2>
            <FullCalendar
                plugins={[timeGridPlugin]}
                initialView="timeGridWeek"
                events={events}
                slotMinTime="07:00:00"
                slotMaxTime="18:00:00"
                allDaySlot={false}
                height="auto" // Adjust height dynamically
                contentHeight="auto" // Ensure dynamic sizing
                expandRows={true} // Prevent rows from collapsing
                headerToolbar={{
                    start: 'title',
                    center: '',
                    end: 'today prev,next',
                }}
                eventContent={renderEventContent} // Render event content
            />
        </div>
    );
};

// Custom event rendering to ensure teacher appears prominently
const renderEventContent = (eventInfo) => {
    const [teacher, group] = eventInfo.event.title.split(' - '); // Split title into teacher and group
    return (
        <div style={{ fontSize: '14px' }}>
            <strong>{teacher}</strong> <br /> {/* Teacher's name */}
            <span>{group}</span> {/* Group name */}
        </div>
    );
};

export default StudentSchedule;
