import React from 'react';

function LogoutButton({ setIsAuthenticated }) {
  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent the default form submission
    fetch('/logout/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-CSRFToken': getCSRFToken(),
      },
      credentials: 'include',
    })
    .then(response => {
      if (response.ok) {
        setIsAuthenticated(false);
        window.location.href = '/'; // Redirect to the home page after logging out
      } else {
        console.error('Logout failed.');
      }
    })
    .catch(error => {
      console.error('Error during logout:', error);
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <input type="hidden" name="csrfmiddlewaretoken" value={getCSRFToken()} />
      <button type="submit">Logout</button>
    </form>
  );

  function getCSRFToken() {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.substring(0, 10) === 'csrftoken=') {
          cookieValue = decodeURIComponent(cookie.substring(10));
          break;
        }
      }
    }
    return cookieValue;
  }
}

export default LogoutButton;
