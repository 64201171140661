import React, { useState, useEffect } from 'react';
import axios from 'axios';

function AddCourse() {
    const [course, setCourse] = useState({ name: '', grade_level_id: '', core_class_id: '' });
    const [gradeLevels, setGradeLevels] = useState([]);
    const [coreClasses, setCoreClasses] = useState([]);
    const [loading, setLoading] = useState(true); // Track loading state
    const [error, setError] = useState(null); // Track errors

    useEffect(() => {
        // Fetch Grade Levels
        axios.get('/courses/api/grade_levels/')
            .then(response => {
                console.log('Grade Levels Response:', response.data); // Log response to inspect
                if (Array.isArray(response.data)) {
                    setGradeLevels(response.data);
                } else {
                    setError('Failed to load grade levels.');
                    console.error('Unexpected response data format:', response.data);
                }
            })
            .catch(error => {
                setError('Error fetching grade levels.'); // Set error state
                console.error('Error fetching grade levels:', error);
            })
            .finally(() => setLoading(false));

        // Fetch Core Classes
        axios.get('/courses/api/core_classes/')
            .then(response => {
                console.log('Core Classes Response:', response.data); // Log response to inspect
                if (Array.isArray(response.data)) {
                    setCoreClasses(response.data);
                } else {
                    setError('Failed to load core classes.');
                    console.error('Unexpected response data format:', response.data);
                }
            })
            .catch(error => {
                setError('Error fetching core classes.'); // Set error state
                console.error('Error fetching core classes:', error);
            });
    }, []);

    const handleChange = (e) => {
        setCourse({ ...course, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post('/courses/api/add/', course)
            .then(response => alert(response.data.message))
            .catch(error => console.error('Error adding course:', error));
    };

    return (
        <form onSubmit={handleSubmit}>
            <input
                type="text"
                name="name"
                value={course.name}
                onChange={handleChange}
                placeholder="Course Name"
                required
            />
            <select
                name="grade_level_id"
                value={course.grade_level_id}
                onChange={handleChange}
                required
                disabled={loading || gradeLevels.length === 0} // Disable if loading or no grade levels
            >
                <option value="">
                    {loading ? 'Loading Grade Levels...' : 'Select Grade Level'}
                </option>
                {Array.isArray(gradeLevels) && gradeLevels.length > 0 ? (
                    gradeLevels.map(grade => (
                        <option key={grade.id} value={grade.id}>{grade.grade}</option>
                    ))
                ) : (
                    <option>No Grade Levels Available</option>
                )}
            </select>
            <select
                name="core_class_id"
                value={course.core_class_id}
                onChange={handleChange}
                required
                disabled={loading || coreClasses.length === 0} // Disable if loading or no core classes
            >
                <option value="">
                    {loading ? 'Loading Core Classes...' : 'Select Core Class'}
                </option>
                {Array.isArray(coreClasses) && coreClasses.length > 0 ? (
                    coreClasses.map(core => (
                        <option key={core.id} value={core.id}>{core.name}</option>
                    ))
                ) : (
                    <option>No Core Classes Available</option>
                )}
            </select>
            <button type="submit" disabled={loading || gradeLevels.length === 0 || coreClasses.length === 0}>
                Add Course
            </button>
            {error && <p style={{ color: 'red' }}>{error}</p>}
        </form>
    );
}

export default AddCourse;
