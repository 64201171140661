import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import './Groups.css'; // Import the CSS file for styling

function AddGroup() {
    const [group, setGroup] = useState({ name: '', grade_level: '', cte_teacher: '' });
    const [gradeLevels, setGradeLevels] = useState([]);
    const [cteTeachers, setCteTeachers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [cookies] = useCookies(['csrftoken']);

    useEffect(() => {
        // Fetch grade levels
        axios.get('/courses/api/grade_levels/')
            .then(response => {
                if (Array.isArray(response.data)) {
                    setGradeLevels(response.data);
                } else {
                    console.error('Unexpected response data format:', response.data);
                }
            })
            .catch(error => console.error('Error fetching grade levels:', error));

        // Fetch CTE teachers
        axios.get('/api/cte-teachers/')
            .then(response => {
                setCteTeachers(response.data);
            })
            .catch(error => console.error('Error fetching CTE teachers:', error))
            .finally(() => setLoading(false));
    }, []);

    const handleChange = (e) => {
        setGroup({ ...group, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const groupData = { ...group };
        if (!group.students || group.students.length === 0) {
            delete groupData.students;
        }

        axios.post('/api/groups/add/', groupData, {
            headers: {
                'X-CSRFToken': cookies.csrftoken
            }
        })
        .then(response => alert(response.data.message))
        .catch(error => {
            console.error('Error adding group:', error);
            if (error.response) {
                alert(`Error: ${error.response.data.message || JSON.stringify(error.response.data)}`);
            } else {
                alert('An unexpected error occurred.');
            }
        });
    };

    return (
        <div className="add-group-container">
            <form className="add-group-form" onSubmit={handleSubmit}>
                <input
                    type="text"
                    name="name"
                    value={group.name}
                    onChange={handleChange}
                    placeholder="Group Name"
                    required
                    className="group-input"
                />
                <select
                    name="grade_level"
                    value={group.grade_level}
                    onChange={handleChange}
                    required
                    disabled={loading || gradeLevels.length === 0}
                    className="group-select"
                >
                    {loading || gradeLevels.length === 0 ? (
                        <option value="">
                            {loading ? 'Loading Grade Levels...' : 'No Grade Levels Available'}
                        </option>
                    ) : (
                        <option value="">Select Grade Level</option>
                    )}
                    {gradeLevels.map(grade => (
                        <option key={grade.id} value={grade.id}>{grade.grade}</option>
                    ))}
                </select>
                <select
                    name="cte_teacher"
                    value={group.cte_teacher}
                    onChange={handleChange}
                    required
                    disabled={loading || cteTeachers.length === 0}
                    className="group-select"
                >
                    <option value="">Select CTE Teacher (optional)</option>
                    {cteTeachers.map(teacher => (
                        <option key={teacher.id} value={teacher.id}>
                            {teacher.first_name} {teacher.last_name}
                        </option>
                    ))}
                </select>
                <button type="submit" className="group-submit-btn" disabled={loading}>
                    Add Group
                </button>
            </form>
        </div>
    );
}

export default AddGroup;
