import React from 'react';
import { useDrop } from 'react-dnd';
import DraggableStudent from './DraggableStudent';

const UnassignedStudents = ({ students = [], onDrop }) => {
    const [{ isOver }, drop] = useDrop(() => ({
        accept: 'student',
        drop: (item) => onDrop(item, null),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    }));

    return (
        <div ref={drop} className={`students-box ${isOver ? 'highlight-drop' : ''}`}>
            {students && students.length > 0 ? (
                students
                    .filter((student) => student && student.id) // Ensure valid entries
                    .map(student => (
                        <DraggableStudent
                            key={student.id}
                            student={student}
                            groupId={null} // Pass `null` for unassigned students
                        />
                    ))
            ) : (
                <p>No unassigned students available.</p>
            )}
        </div>
    );
};

export default UnassignedStudents;
