// src/components/HomeroomUpload.js
import React, { useState } from 'react';
import axios from 'axios';

// Function to get the CSRF token from the browser's cookies
function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

function HomeroomUpload() {
    const [file, setFile] = useState(null);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('file', file);

        const csrfToken = getCookie('csrftoken'); // Get CSRF token

        axios.post('/students/api/upload-homeroom-teachers/', formData, {
            headers: {
                'X-CSRFToken': csrfToken // Include CSRF token in headers
            }
        })
        .then(response => {
            alert(`Success: ${response.data.message}`);
            if (response.data.errors.length > 0) {
                alert(`Errors:\n${response.data.errors.join('\n')}`);
            }
        })
        .catch(error => {
            console.error('Error uploading homeroom teachers:', error);
            alert(`Error: ${error.response.data.message}`);
        });
    };

    return (
        <div>
            <h2>Upload Homeroom Teachers</h2>
            <form onSubmit={handleSubmit}>
                <input type="file" onChange={handleFileChange} accept=".csv" required />
                <button type="submit">Upload CSV</button>
            </form>
        </div>
    );
}

export default HomeroomUpload;
