// src/components/Teachers/TeacherDashboard.js
import React from 'react';
import {Link} from "react-router-dom";

const TeacherDashboard = () => {
  return (
      <div>
          <h1>Teacher Dashboard</h1>
          <p>Welcome to your dashboard. You can manage groups, students, and take attendace from here.</p>
          {/* Add links or sections for teacher-specific actions */}
          <div className="feature-links">
              <Link to="/student-home" className="feature-button">Manage Students</Link>
              {/*<Link to="/courses" className="feature-button">Manage Courses</Link>*/}
              <Link to="/groups/" className="feature-button">Manage Groups</Link>
              <Link to="/schedules" className="feature-button">Manage Schedules</Link>
              <Link to="/attendance" className="feature-button">Take Attendance</Link> {/* New Attendance Link */}
          </div>
      </div>
  );
};

export default TeacherDashboard;
