// frontend/src/components/Schedules/ViewAssignedGroups.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';

function ViewAssignedGroups({ gradeLevelId }) {
    const [classPeriods, setClassPeriods] = useState([]);

    useEffect(() => {
        axios.get(`/schedules/api/class-periods/assigned_groups/${gradeLevelId}/`)
            .then(response => setClassPeriods(response.data))
            .catch(error => console.error('Error fetching assigned groups:', error));
    }, [gradeLevelId]);

    return (
        <div>
            <h2>Assigned Groups by Class Period</h2>
            {classPeriods.length > 0 ? (
                classPeriods.map(cpData => (
                    <div key={cpData.class_period.id}>
                        <h3>{cpData.class_period.name} ({cpData.class_period.start_time} - {cpData.class_period.end_time})</h3>
                        <ul>
                            {cpData.groups.map(group => (
                                <li key={group.id}>{group.name}</li>
                            ))}
                        </ul>
                    </div>
                ))
            ) : (
                <p>No class periods found for this grade level.</p>
            )}
        </div>
    );
}

export default ViewAssignedGroups;
