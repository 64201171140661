import React, { useState, useEffect } from 'react';
import axios from 'axios';

const getCSRFToken = () => {
    const cookies = document.cookie.split(';');
    for (let cookie of cookies) {
        const [name, value] = cookie.trim().split('=');
        if (name === 'csrftoken') {
            return value;
        }
    }
    return null;
};

const SectionPeriodCreate = ({ blockSchedule, gradeLevel, onSectionPeriodCreated }) => {
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [daysOfWeek, setDaysOfWeek] = useState([]);
    const [availableDays, setAvailableDays] = useState([]);
    const [homeroom, setHomeroom] = useState(false);  // New state for homeroom


    useEffect(() => {
        setAvailableDays(['Monday', 'Tuesday', 'Wednesday', 'Thursday']);
    }, []);

    // Handle form submission for creating a section period
    const handleSubmit = (e) => {
        e.preventDefault();
        const sectionPeriodData = {
            start_time: startTime,
            end_time: endTime,
            block_schedule: blockSchedule,
            grade_level: gradeLevel,
            days_of_week: daysOfWeek,  // Selected days of the week
            homeroom: homeroom,  // Include homeroom
        };

        axios.post('/schedules/api/section-periods/', sectionPeriodData, {
            headers: { 'X-CSRFToken': getCSRFToken() },
        })
        .then(response => {
            onSectionPeriodCreated();  // Trigger parent callback to refresh the list
            setStartTime('');
            setEndTime('');
            setDaysOfWeek([]);
            setHomeroom(false);  // Reset homeroom
        })
        .catch(error => console.error('Error creating section period:', error));
    };

    const handleDaySelection = (e) => {
        const selectedDay = e.target.value;
        if (daysOfWeek.includes(selectedDay)) {
            setDaysOfWeek(daysOfWeek.filter(day => day !== selectedDay));
        } else {
            setDaysOfWeek([...daysOfWeek, selectedDay]);
        }
    };

    return (
        <div>
            <h4>Create Section Period</h4>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Start Time:</label>
                    <input
                        type="time"
                        value={startTime}
                        onChange={(e) => setStartTime(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>End Time:</label>
                    <input
                        type="time"
                        value={endTime}
                        onChange={(e) => setEndTime(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Select Days of the Week:</label>
                    <div>
                        {availableDays.map(day => (
                            <label key={day}>
                                <input
                                    type="checkbox"
                                    value={day}
                                    checked={daysOfWeek.includes(day)}
                                    onChange={handleDaySelection}
                                />
                                {day}
                            </label>
                        ))}
                    </div>
                    <div>
                        <label>
                            Homeroom:
                            <input
                                type="checkbox"
                                checked={homeroom}
                                onChange={(e) => setHomeroom(e.target.checked)}
                            />
                        </label>
                    </div>
                </div>
                <button type="submit">Create</button>
            </form>
        </div>
    );
};

export default SectionPeriodCreate;
