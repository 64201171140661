// src/components/StudentHome.js
import React from 'react';
import { Link } from 'react-router-dom';

function StudentHome() {
    return (
        <div className="container">
            <h1>Student Dashboard</h1>
            <ul>
                <li><Link to="/students/upload-students">Upload Students</Link></li>
                <li><Link to="/students/add">Add Student</Link></li>
                <li><Link to="/students/list">List Students</Link></li>
                <li><Link to="/students/upload-homeroom">Upload Homeroom Teachers</Link></li> {/* New Link */}
            </ul>
        </div>
    );
}

export default StudentHome;
