import React, {useEffect, useState} from 'react';
import {getCSRFToken} from './components/utils/csrf'; // Adjust path if placed elsewhere
import { useUser } from './UserContext';
import {BrowserRouter as Router, Routes, Route, Link, Navigate, useNavigate} from 'react-router-dom';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import './App.css';
import axios from 'axios';
import LogoutButton from './components/LogoutButton';
import LoginButton from './components/LoginButton';

// Existing imports
import Index from './components/Index';
import StudentHome from './components/Students/StudentHome';
import StudentForm from './components/Students/StudentForm';
import StudentList from './components/Students/StudentList';
import EditStudent from './components/Students/EditStudent';
import StudentUpload from './components/Students/StudentUpload';
import HomeroomUpload from './components/Students/HomeroomUpload';
import CourseHome from './components/Courses/CourseHome';
import AddCourse from './components/Courses/AddCourse';
import CourseList from './components/Courses/CourseList';
import EditCourse from './components/Courses/EditCourse';
import AddGroup from './components/Groups/AddGroup';
import EditGroup from './components/Groups/EditGroup';
import AssignHomeroom from './components/Groups/AssignHomeroom';
import GroupList from './components/Groups/GroupList';
import GroupsHome from './components/Groups/GroupsHome';
import ManageGroups from './components/Groups/ManageGroups';
import ManageCTEGroups from './components/Groups/ManageCTEGroups';
import ScheduleHome from './components/Schedule/ScheduleHome';
import ViewSectionPeriods from './components/Schedule/ViewSectionPeriods';
import EditSectionPeriod from "./components/Schedule/EditSectionPeriod";

import BlockScheduleList from './components/Schedule/BlockScheduleList';
import ViewAssignedGroups from './components/Schedule/ViewAssignedGroups';
import NotFound from './components/NotFound';
import TeacherDashboard from './components/Teachers/TeacherDashboard';
import StudentDashboard from './components/Students/StudentDashboard';

// New imports for Schedule Management
import SectionPeriodManager from './components/Schedule/SectionPeriodManager'; // <-- Import SectionPeriodManager
import AssignGroups from './components/Schedule/AssignGroups';
import ScheduleTool from './components/Schedule/ScheduleTool';
import CTEScheduleTool from './components/Schedule/CTEScheduleTool';

// New imports for Attendance Management
import AttendanceHome from './components/Attendance/AttendanceHome';
import TakeAttendance from './components/Attendance/TakeAttendance';
import StudentSchedule from "./components/Students/StudentSchedule";


// Axios CSRF Configuration
axios.defaults.headers.common['X-CSRFToken'] = getCSRFToken();
axios.defaults.withCredentials = true; // Ensure cookies are sent with requests


function App() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [checkingAuth, setCheckingAuth] = useState(true);
    const [userType, setUserType] = useState(null);
    const [checkingUserType, setCheckingUserType] = useState(false);
    const { currentUser, setCurrentUser } = useUser();

    const checkAuthentication = async () => {
        try {
            const response = await axios.get('/api/check-authentication/', {withCredentials: true});
            setIsAuthenticated(response.data.isAuthenticated);
            setCheckingAuth(false);
        } catch (error) {
            console.error("Error checking authentication:", error);
            setIsAuthenticated(false);
            setCheckingAuth(false);
        }
    };

    const checkUserType = async () => {
        setCheckingUserType(true);
        try {
            const response = await axios.get('/api/check-user-type/', {withCredentials: true});
            if (response.data.isTeacher) {
                setUserType('teacher');
            } else if (response.data.isStudent) {
                setUserType('student');
            } else {
                setUserType(null);
            }
        } catch (error) {
            console.error("Error checking user type:", error);
            setUserType(null);
        }
        setCheckingUserType(false);
    };

    useEffect(() => {
        checkAuthentication();
    }, []);

    useEffect(() => {
        if (isAuthenticated) {
            checkUserType();
        }
    }, [isAuthenticated]);

    // Fetch and persist currentUser
    useEffect(() => {
        const storedUser = localStorage.getItem('currentUser');
        if (storedUser) {
            setCurrentUser(JSON.parse(storedUser));
        } else {
            axios.get('/api/current-user/')
                .then(response => {
                    setCurrentUser(response.data);
                    localStorage.setItem('currentUser', JSON.stringify(response.data)); // Persist user
                })
                .catch(error => console.error('Error fetching current user:', error));
        }
    }, []);

    if (checkingAuth || checkingUserType) {
        return <div>Loading...</div>;
    }

    return (
        <DndProvider backend={HTML5Backend}>
            <Router>
                <div className="App">
                    <header className="App-header">
                        <nav className="nav">
                            <div className="nav-brand">
                                <Link to="/">Group Track</Link>
                            </div>
                            <ul className="nav-links">
                                <li><Link to="/">Home</Link></li>
                                {isAuthenticated ? (
                                    <li><LogoutButton setIsAuthenticated={setIsAuthenticated}/></li>
                                ) : (
                                    <li><LoginButton/></li>
                                )}
                            </ul>
                        </nav>
                    </header>

                    <main>
                        <Routes>
                            <Route path="/" element={!isAuthenticated ? <Index/> :
                                <Navigate to={userType === 'teacher' ? "/teacher-dashboard" : "/student-dashboard"}/>}/>
                            <Route path="/student-home" element={<StudentHome/>}/>
                            <Route path="/students/add" element={<StudentForm/>}/>
                            <Route path="/students/list" element={<StudentList/>}/>
                            <Route path="/edit-student/:studentId" element={<EditStudent/>}/>
                            <Route path="/students/upload-students" element={<StudentUpload/>}/>
                            <Route path="/students/upload-homeroom" element={<HomeroomUpload/>}/>
                            <Route path="/courses" element={<CourseHome/>}/>
                            <Route path="/courses/add" element={<AddCourse/>}/>
                            <Route path="/courses/list" element={<CourseList/>}/>
                            <Route path="/courses/edit/:id" element={<EditCourse/>}/>
                            <Route path="/groups" element={<GroupsHome/>}/>
                            <Route path="/groups/add" element={<AddGroup/>}/>
                            <Route path="/groups/edit/:id" element={<EditGroup/>}/>
                            <Route path="/groups/assign-homeroom" element={<AssignHomeroom/>}/>
                            <Route path="/groups/list" element={<GroupList/>}/>
                            <Route path="/groups/manage" element={<ManageGroups/>}/>
                            <Route path="/groups/manage-cte" element={<ManageCTEGroups/>}/>
                            <Route path="/manage-cte-groups" element={<ManageCTEGroups/>}/>


                            {isAuthenticated && (
                                <>
                                    <Route path="/schedules" element={<ScheduleHome/>}/>
                                    <Route path="/schedules/create-section" element={
                                        <SectionPeriodManager/>}/> {/* <-- Updated to SectionPeriodManager */}
                                    <Route path="/schedules/view-sections" element={<ViewSectionPeriods/>}/>
                                    <Route path="/edit-section-period/:id" element={<EditSectionPeriod/>}/>

                                    <Route path="/schedules/assign-groups/:sectionPeriodId" element={<AssignGroups/>}/>
                                    <Route path="/schedules/block-schedules" element={<BlockScheduleList/>}/>
                                    <Route path="/schedules/view-assigned-groups/:gradeLevelId"
                                           element={<ViewAssignedGroups/>}/>
                                    <Route path="/schedules/tool" element={<ScheduleTool/>}/>

                                    <Route path="/teacher-dashboard"
                                           element={userType === 'teacher' ? <TeacherDashboard/> : <Navigate to="/"/>}/>
                                    <Route path="/student-dashboard"
                                           element={userType === 'student' ? <StudentDashboard/> : <Navigate to="/"/>}/>
                                    <Route path="/attendance"
                                           element={<AttendanceHome/>}/> {/* Correct syntax for v6 */}
                                    <Route path="/attendance/take/:sectionPeriodId" element={<TakeAttendance/>}/>
                                    <Route path="/schedule/:studentId" element={<StudentSchedule/>}/> {/* New route */}
                                </>
                            )}

                            <Route path="*" element={<Navigate to="/" />} />
                        </Routes>
                    </main>

                    <footer>
                        <p>&copy; {new Date().getFullYear()} Group Track. All rights reserved.</p>
                    </footer>
                </div>
            </Router>
        </DndProvider>
    );
}

export default App;
