// src/components/Index.js
import React from 'react';
import { Link } from 'react-router-dom';
import LogoutButton from './LogoutButton';  // Import the LogoutButton component
function Index() {
    console.log("Index component rendered");  // Debug log to check rendering
    return (
        <div className="container">
            <h1>Welcome to Elevate Nampa Group Track</h1>

        </div>
    );
}

export default Index;
