// frontend/src/components/StudentForm.js
import React, { useState } from 'react';
import axios from 'axios';

function StudentForm() {
    const [student, setStudent] = useState({
        first_name: '',
        last_name: '',
        grade_level: '',
        email: '',  // Added email field
    });

    const handleChange = (e) => {
        setStudent({ ...student, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post('/students/api/add/', student)
            .then(response => {
                console.log('Student added:', response.data);
                alert('Student added successfully!');
            })
            .catch(error => {
                console.error('There was an error adding the student!', error);
            });
    };

    return (
        <form onSubmit={handleSubmit}>
            <input
                type="text"
                name="first_name"
                value={student.first_name}
                onChange={handleChange}
                placeholder="First Name"
                required
            />
            <input
                type="text"
                name="last_name"
                value={student.last_name}
                onChange={handleChange}
                placeholder="Last Name"
                required
            />
            <input
                type="number"
                name="grade_level"
                value={student.grade_level}
                onChange={handleChange}
                placeholder="Grade Level"
                required
            />
            <input
                type="email"
                name="email"
                value={student.email}
                onChange={handleChange}  // Handle email input change
                placeholder="Email"
                required
            />
            <button type="submit">Add Student</button>
        </form>
    );
}

export default StudentForm;
