import React from 'react';
import { useDrag } from 'react-dnd';

const DraggableStudent = ({ student, groupId }) => {


    const [{ isDragging }, drag] = useDrag(() => ({
        type: 'student',
        item: { id: student.id, groupId },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    }));

    return (
        <div
            ref={drag}
            className="student-item"
            style={{
                opacity: isDragging ? 0.5 : 1,
                cursor: 'grab',
            }}
        >
            {student.first_name} {student.last_name}
        </div>
    );
};

export default DraggableStudent;
