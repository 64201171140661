// frontend/src/components/Groups/GroupsHome.js
import React from 'react';
import { Link } from 'react-router-dom';
import './Groups.css'; // Assuming you have some CSS for styling

function GroupsHome() {
    return (
        <div >
            <h1>Groups Management</h1>
            <div className="feature-links">
                {/* <Link to="/groups/add" className="feature-button">
                    Add Group
                 </Link>/}
               {/* <Link to="/groups/list" className="group-link">
                    <button>View Groups</button>
                </Link>*/}
                {/* <Link to="/groups/assign-homeroom" className="feature-button">
                    Homeroom Students
                </Link>/}
                {/* New Link to Manage Groups */}
                <Link to="/groups/manage" className="feature-button">
                    Manage Core Groups
                </Link>
                <Link to="/groups/manage-cte" className="feature-button">
                    Manage CTE Groups
                </Link>
            </div>
        </div>
    );
}

export default GroupsHome;
