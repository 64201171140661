import React, {useState, useEffect} from 'react';
import axios from 'axios';
import SectionPeriodCreate from './SectionPeriodCreate';
import SectionPeriodList from './SectionPeriodList';
import DuplicateSectionPeriods from './DuplicateSectionPeriods';

const SectionPeriodManager = () => {
    const [blockSchedules, setBlockSchedules] = useState([]);  // Ensure it's an array
    const [gradeLevels, setGradeLevels] = useState([]);        // Ensure it's an array
    const [selectedBlockSchedule, setSelectedBlockSchedule] = useState('');
    const [selectedGradeLevel, setSelectedGradeLevel] = useState('');
    const [refreshList, setRefreshList] = useState(false);
    const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);


    // Fetch block schedules and grade levels when the component loads
    useEffect(() => {
        const fetchBlockSchedules = async () => {
            try {
                const response = await axios.get('/schedules/api/block-schedules/');
                console.log('Block Schedules:', response.data);
                setBlockSchedules(Array.isArray(response.data) ? response.data : []);  // Ensure it's an array
            } catch (error) {
                console.error('Error fetching block schedules:', error);
                setBlockSchedules([]);  // Set to empty array on error
            }
        };

        const fetchGradeLevels = async () => {
            try {
                const response = await axios.get('/courses/api/grade_levels/');
                console.log('Grade Levels:', response.data);
                setGradeLevels(Array.isArray(response.data) ? response.data : []);  // Ensure it's an array
            } catch (error) {
                console.error('Error fetching grade levels:', error);
                setGradeLevels([]);  // Set to empty array on error
            }
        };

        fetchBlockSchedules();
        fetchGradeLevels();
    }, []);

    // Handle block schedule change
    const handleBlockScheduleChange = (e) => {
        setSelectedBlockSchedule(e.target.value);
    };

    // Handle grade level change
    const handleGradeLevelChange = (e) => {
        setSelectedGradeLevel(e.target.value);
    };

    const handleOpenDuplicateModal = () => {
        setIsDuplicateModalOpen(true);
    };

    const handleCloseDuplicateModal = () => {
        setIsDuplicateModalOpen(false);
    };

    return (<div>
            <h2>Manage Section Periods</h2>

            {/* Select Block Schedule */}
            <label>Select Block Schedule:</label>
            <select value={selectedBlockSchedule} onChange={handleBlockScheduleChange}>
                <option value="">-- Select Block Schedule --</option>
                {blockSchedules.length > 0 ? (blockSchedules.map(bs => (
                        <option key={bs.id} value={bs.id}>{bs.name}</option>))) : (
                    <option disabled>No Block Schedules Available</option>)}
            </select>

            {/* Select Grade Level */}
            <label>Select Grade Level:</label>
            <select value={selectedGradeLevel} onChange={handleGradeLevelChange}>
                <option value="">-- Select Grade Level --</option>
                {gradeLevels.length > 0 ? (gradeLevels.map(gl => (<option key={gl.id} value={gl.id}>{gl.grade}</option>  // Ensure correct field is used
                    ))) : (<option disabled>No Grade Levels Available</option>)}
            </select>
            <button onClick={handleOpenDuplicateModal}>Duplicate Section Periods</button>
            {isDuplicateModalOpen && (<DuplicateSectionPeriods
                    blockSchedules={blockSchedules}
                    onClose={handleCloseDuplicateModal}
                />)}

            {/* SectionPeriodCreate and SectionPeriodList Components */}
            <SectionPeriodCreate
                blockSchedule={selectedBlockSchedule}
                gradeLevel={selectedGradeLevel}
                onSectionPeriodCreated={() => setRefreshList(!refreshList)}
            />
            <SectionPeriodList
                blockSchedule={selectedBlockSchedule}
                gradeLevel={selectedGradeLevel}
                refreshList={refreshList}
            />
        </div>);
};

export default SectionPeriodManager;
