import React, { useState } from 'react';
import axios from 'axios';

// Function to get the CSRF token from the browser's cookies
function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

function StudentUpload() {
    const [file, setFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false); // Track upload state

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!file) {
            alert('Please select a file to upload.');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        const csrfToken = getCookie('csrftoken'); // Get the CSRF token
        setIsUploading(true);

        try {
            const response = await axios.post('/students/api/upload-students/', formData, {
                headers: {
                    'X-CSRFToken': csrfToken, // Include the CSRF token in the headers
                    'Content-Type': 'multipart/form-data', // Explicitly set multipart/form-data
                },
            });

            alert('File uploaded successfully!');
            console.log('Upload response:', response.data);
        } catch (error) {
            console.error('There was an error uploading the file!', error);
            const errorMessage =
                error.response && error.response.data && error.response.data.error
                    ? error.response.data.error
                    : 'Unknown error occurred.';
            alert(`Error: ${errorMessage}`);
        } finally {
            setIsUploading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="student-upload-form">
            <label htmlFor="file-upload" className="file-upload-label">
                Select an Excel file:
            </label>
            <input
                type="file"
                id="file-upload"
                onChange={handleFileChange}
                accept=".xlsx"
                required
                className="file-upload-input"
            />
            <button type="submit" className="upload-button" disabled={isUploading}>
                {isUploading ? 'Uploading...' : 'Upload'}
            </button>
        </form>
    );
}

export default StudentUpload;
