import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DroppableGroupCTE from './DroppableGroupCTE';
import UnassignedStudentsCTE from './UnassignedStudentsCTE';
import RemoveGroup from './RemoveGroup';

import './Groups.css';

const ManageCTEGroups = () => {
    const [currentUser, setCurrentUser] = useState(null);
    const [groupData, setGroupData] = useState({
        cte_primary: { groups: [], unassignedStudents: [] },
        cte_skills: { groups: [], unassignedStudents: [] },
    });
    const [currentGroupType, setCurrentGroupType] = useState('cte_primary');
    const [newGroupName, setNewGroupName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [cookies] = useCookies(['csrftoken']);

    // Fetch current user
    useEffect(() => {
        const storedUser = localStorage.getItem('currentUser');
        if (storedUser) {
            setCurrentUser(JSON.parse(storedUser));
        } else {
            axios
                .get('/api/current-user/')
                .then(response => {
                    setCurrentUser(response.data);
                    localStorage.setItem('currentUser', JSON.stringify(response.data));
                })
                .catch(error => console.error('Error fetching current user:', error));
        }
    }, []);

    // Fetch group data based on CTE teacher and group type
    const fetchTeacherGroupsAndStudents = async (teacherId, groupType) => {
        console.log(`Fetching groups and students for teacher_id=${teacherId}, group_type=${groupType}`);
        setIsLoading(true);

        try {
            const response = await axios.get('/api/groups/cte-teacher-groups/', {
                params: { teacher_id: teacherId, group_type: groupType },
            });
            const fetchedData = response.data;

            console.log('Fetched data from backend:', fetchedData);

            if (!fetchedData || typeof fetchedData !== 'object') {
                throw new Error('Invalid response from backend.');
            }

            const groups = fetchedData.groups || [];
            const unassignedStudents = fetchedData.unassigned_students || [];

            setGroupData(prevGroupData => ({
                ...prevGroupData,
                [groupType]: {
                    groups,
                    unassignedStudents,
                },
            }));
        } catch (error) {
            console.error('Error fetching teacher groups and students:', error.message);
            alert('Error fetching data. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    // Trigger fetch on user or group type change
    useEffect(() => {
        if (currentUser && currentUser.teacher_id) {
            fetchTeacherGroupsAndStudents(currentUser.teacher_id, currentGroupType);
        }
    }, [currentUser, currentGroupType]);

    // Handle drop action
    const handleDrop = async (item, targetGroup) => {
        console.log('Handling drop:', { item, targetGroup });


        const groupType = item.groupId
            ? Object.keys(groupData).find(type =>
                  groupData[type]?.groups.some(group => group.id === item.groupId)
              )
            : currentGroupType;

        if (!groupType) {
            console.error(`Error: Could not determine groupType for groupId ${item.groupId}`);
            alert('Error determining group type. Please refresh and try again.');
            return;
        }

        console.log('Determined groupType:', groupType);

        try {
            if (!targetGroup) {
                console.log('Moving student to unassigned list');
                await axios.post(
                    '/api/groups/update_group_membership/',
                    { student_id: item.id, current_group_id: item.groupId, new_group_id: null },
                    { headers: { 'X-CSRFToken': cookies.csrftoken } }
                );

                setGroupData(prevGroupData => {
                    const updatedGroups = prevGroupData[groupType]?.groups.map(group =>
                        group.id === item.groupId
                            ? { ...group, students: group.students.filter(student => student.id !== item.id) }
                            : group
                    );

                    const updatedUnassignedStudents = [
                        ...prevGroupData[groupType]?.unassignedStudents,
                        prevGroupData[groupType]?.groups
                            .flatMap(group => group.students)
                            .find(student => student.id === item.id),
                    ];

                    return {
                        ...prevGroupData,
                        [groupType]: {
                            groups: updatedGroups,
                            unassignedStudents: updatedUnassignedStudents,
                        },
                    };
                });

                console.log('Student successfully moved to unassigned list.');
                return;
            }

            console.log('Moving student to group:', targetGroup.name);
            await axios.post(
                '/api/groups/update_group_membership/',
                { student_id: item.id, current_group_id: item.groupId, new_group_id: targetGroup.id },
                { headers: { 'X-CSRFToken': cookies.csrftoken } }
            );

            setGroupData(prevGroupData => {
                const student =
                    prevGroupData[groupType]?.unassignedStudents.find(s => s.id === item.id) ||
                    prevGroupData[groupType]?.groups.flatMap(group => group.students).find(s => s.id === item.id);

                const updatedGroups = prevGroupData[groupType]?.groups.map(group => {
                    if (group.id === targetGroup.id) {
                        return { ...group, students: [...group.students, student] };
                    }
                    if (group.id === item.groupId) {
                        return { ...group, students: group.students.filter(s => s.id !== item.id) };
                    }
                    return group;
                });

                const updatedUnassignedStudents = prevGroupData[groupType]?.unassignedStudents.filter(
                    s => s.id !== item.id
                );

                return {
                    ...prevGroupData,
                    [groupType]: {
                        groups: updatedGroups,
                        unassignedStudents: updatedUnassignedStudents,
                    },
                };
            });

            console.log('Student successfully moved.');
        } catch (error) {
            console.error('Error during handleDrop:', error.message);
            alert('Error updating group membership. Please try again.');
        }
    };

    const createGroups = () => {
        const numGroups = parseInt(prompt('Enter the number of groups to create:'), 10);
        if (!numGroups || numGroups <= 0) {
            alert('Please enter a valid number of groups.');
            return;
        }

        axios
            .post(
                '/api/groups/cte-auto-create-groups/',
                { cte_teacher: currentUser.teacher_id, num_groups: numGroups, group_type: currentGroupType },
                { headers: { 'X-CSRFToken': cookies.csrftoken } }
            )
            .then(response => {
                alert(response.data.message);
                fetchTeacherGroupsAndStudents(currentUser.teacher_id, currentGroupType);
            })
            .catch(error => alert('Error creating groups: ' + error.message));
    };

    const addGroupManually = () => {
        if (!newGroupName.trim()) {
            alert('Group name cannot be empty.');
            return;
        }

        axios
            .post(
                '/api/groups/add-cte/',
                { name: newGroupName, cte_teacher: currentUser.teacher_id, group_type: currentGroupType },
                { headers: { 'X-CSRFToken': cookies.csrftoken } }
            )
            .then(() => {
                setNewGroupName('');
                fetchTeacherGroupsAndStudents(currentUser.teacher_id, currentGroupType);
            })
            .catch(error => alert('Error adding group: ' + error.message));
    };

    if (isLoading) {
        return <p>Loading...</p>;
    }

    return (
        <DndProvider backend={HTML5Backend}>
            <div className="groups-home">
                <h2>Manage CTE Groups</h2>
                <div className="top-section">
                    <label htmlFor="group-type-select">Group Type:</label>
                    <select
                        id="group-type-select"
                        value={currentGroupType}
                        onChange={e => setCurrentGroupType(e.target.value)}
                    >
                        <option value="cte_primary">CTE Primary</option>
                        <option value="cte_skills">CTE Skills</option>
                        <option value="homeroom">Homeroom</option>
                    </select>
                    <input
                        type="text"
                        value={newGroupName}
                        onChange={e => setNewGroupName(e.target.value)}
                        placeholder="Enter new group name"
                    />
                    <button onClick={addGroupManually}>Add Group</button>
                    <button onClick={createGroups}>Auto-Create Groups</button>
                </div>
                <div className="layout">
                    <UnassignedStudentsCTE
                        students={groupData[currentGroupType]?.unassignedStudents || []}
                        onDrop={item => handleDrop(item, null)}
                    />
                    <div className="group-list">
                        {groupData[currentGroupType]?.groups.map(group => (
                            <div key={group.id} className="group-container">
                                <div className="group-header">
                                    <h3>{group.name}</h3>
                                    <RemoveGroup
                                        groupId={group.id}
                                        groupName={group.name}
                                        cteTeacher={currentUser.teacher_id}
                                        onRemove={(removedGroupId, removedStudents) =>
                                            setGroupData(prevGroupData => {
                                                const updatedGroups = prevGroupData[currentGroupType].groups.filter(
                                                    group => group.id !== removedGroupId
                                                );
                                                const updatedUnassignedStudents = [
                                                    ...prevGroupData[currentGroupType].unassignedStudents,
                                                    ...removedStudents.map(student => ({
                                                        id: student.id,
                                                        first_name: student.first_name,
                                                        last_name: student.last_name,
                                                    })),
                                                ];
                                                return {
                                                    ...prevGroupData,
                                                    [currentGroupType]: {
                                                        groups: updatedGroups,
                                                        unassignedStudents: updatedUnassignedStudents,
                                                    },
                                                };
                                            })
                                        }
                                    />
                                </div>
                                <DroppableGroupCTE group={group} onDrop={handleDrop} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </DndProvider>
    );
};

export default ManageCTEGroups;
