import React, { useState, useEffect } from 'react';
import { useDrag } from 'react-dnd';
import axios from 'axios';
import './schedule.css';

const Group = ({ group }) => {
    const [{ isDragging }, drag] = useDrag({
        type: 'GROUP',
        item: { id: group.id, name: group.name },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    useEffect(() => {
        if (isDragging) {
            console.log(`Dragging group: ${group.name}`);
        }
    }, [isDragging, group.name]);

    return (
        <div
            ref={drag}
            className="group-item"
            style={{ opacity: isDragging ? 0.5 : 1 }}
        >
            {group.name}
        </div>
    );
};

const DraggableGroups = ({ gradeLevelId }) => {
    const [groups, setGroups] = useState([]);
    const [gradeLevelName, setGradeLevelName] = useState("");

    useEffect(() => {
        if (gradeLevelId) {
            axios.get(`/api/groups/grade-level/${gradeLevelId}/`)
                .then(response => setGroups(response.data))
                .catch(error => console.error('Error fetching groups:', error));
        }
    }, [gradeLevelId]);

    useEffect(() => {
        if (gradeLevelId) {
            axios.get(`/courses/api/grade_levels/${gradeLevelId}/`)
                .then(response => setGradeLevelName(response.data.grade))
                .catch(error => console.error('Error fetching grade level name:', error));
        }
    }, [gradeLevelId]);

    return (
        <div className="available-groups">
            <h3>Available Groups for {gradeLevelName} Grade</h3>
            {groups.length > 0 ? (
                groups.map(group => (
                    <Group key={group.id} group={group} />
                ))
            ) : (
                <p>No groups available for this grade level.</p>
            )}
        </div>
    );
};

export default DraggableGroups;
