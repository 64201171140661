// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';  // Use 'react-dom/client' instead of 'react-dom'
import App from './App';
import './index.css';
import reportWebVitals from "./reportWebVitals";
import { UserProvider } from './UserContext'; // Import the UserProvider

const root = ReactDOM.createRoot(document.getElementById('root'));  // Create a root using createRoot
root.render(
  <React.StrictMode>
       <UserProvider>
    <App />
       </UserProvider>
  </React.StrictMode>
);


// Optional: To measure performance in your app, pass a function to log results (e.g., reportWebVitals(console.log))
reportWebVitals();
