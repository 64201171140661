import React, { useEffect } from 'react';
import { useDrop } from 'react-dnd';
import DraggableStudentCTE from './DraggableStudentCTE';

const UnassignedStudentsCTE = ({ students = [], onDrop }) => {
    useEffect(() => {
        console.log('Rendering UnassignedStudentsCTE with students:', students);
    }, [students]);

    const [{ isOver }, drop] = useDrop(() => ({
        accept: 'student',
        drop: (item) => {

            console.log(`Dropping student ${item.id} to unassigned`);
            if (onDrop) {
                onDrop(item, null); // Explicitly pass null for targetGroup
            } else {
                console.warn('onDrop is not defined in UnassignedStudentsCTE');
            }
        },
        collect: (monitor) => ({
            isOver: monitor.isOver(),
        }),
    }));

    return (
        <div ref={drop} className={`students-box ${isOver ? 'highlight-drop' : ''}`}>
            {students.length > 0 ? (
                students.map((student) => (
                    <DraggableStudentCTE key={student.id} student={student} groupId={null} />
                ))
            ) : (
                <p>No unassigned students available.</p>
            )}
        </div>
    );
};

export default UnassignedStudentsCTE;
