import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

function StudentList() {
    const [students, setStudents] = useState([]);
    const [filteredStudents, setFilteredStudents] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        // Fetch the list of students
        axios.get('/students/api/list/')
            .then(response => {
                setStudents(response.data);
                setFilteredStudents(response.data); // Initialize filtered list
            })
            .catch(error => {
                console.error('There was an error fetching the student list!', error);
            });
    }, []);

    const handleSearch = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);

        // Filter students based on the search query
        const filtered = students.filter(student =>
            `${student.first_name} ${student.last_name}`.toLowerCase().includes(query)
        );
        setFilteredStudents(filtered);
    };

    return (
        <div>
            <h1>Student List</h1>
            <input
                type="text"
                placeholder="Search by name..."
                value={searchQuery}
                onChange={handleSearch}
                style={{
                    padding: '8px',
                    marginBottom: '20px',
                    width: '100%',
                    maxWidth: '300px',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                }}
            />
            <ul>
                {filteredStudents.map(student => (
                    <li key={student.id}>
                        {student.first_name} {student.last_name} - {student.email}
                        <Link to={`/schedule/${student.id}`} style={{ marginLeft: '10px' }}>View Schedule</Link>
                        <Link to={`/edit-student/${student.id}`} style={{ marginLeft: '10px' }}>Edit Student</Link>
                    </li>
                ))}
            </ul>
            {filteredStudents.length === 0 && <p>No students found matching "{searchQuery}"</p>}
        </div>
    );
}

export default StudentList;
