import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {useCookies} from 'react-cookie';
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import DraggableStudent from './DraggableStudent';
import DroppableGroup from './DroppableGroup';
import UnassignedStudents from './UnassignedStudents';
import RemoveGroup from './RemoveGroup';

import './Groups.css';

const ManageGroups = () => {
    const [gradeLevels, setGradeLevels] = useState([]);
    const [selectedGradeLevel, setSelectedGradeLevel] = useState('');
    const [groupTypes, setGroupTypes] = useState([]);
    const [currentGroupType, setCurrentGroupType] = useState('core'); // Track current group type
    const [groupData, setGroupData] = useState({
        core: {groups: [], unassignedStudents: []},
        cte: {groups: [], unassignedStudents: []},
        pe: {groups: [], unassignedStudents: []},
        other: {groups: [], unassignedStudents: []},
        homeroom: {groups: [], unassignedStudents: []},
    });
    const [newGroupName, setNewGroupName] = useState(''); // For manual group creation
    const [cookies] = useCookies(['csrftoken']);
    const [loading, setLoading] = useState(false);

    // Fetch grade levels on component mount
    useEffect(() => {
        axios.get('/courses/api/grade_levels/')
            .then(response => setGradeLevels(response.data))
            .catch(error => console.error('Error fetching grade levels:', error));
    }, []);

    // Fetch group types dynamically
    useEffect(() => {
        axios.get('/api/groups/group-types/')  // Use the correct API endpoint
            .then(response => {
                console.log("Fetched group types:", response.data);
                if (Array.isArray(response.data)) {
                    setGroupTypes(response.data);
                } else {
                    console.error("Unexpected response format for group types:", response.data);
                    setGroupTypes([]);  // Ensure it's always an array
                }
            })
            .catch(error => {
                console.error('Error fetching group types:', error);
                setGroupTypes([]); // Prevents map errors
            });
    }, []);


    // Fetch grade level data
    const fetchGradeLevelData = async (gradeLevel, groupType) => {
        console.log(`Fetching data for gradeLevel=${gradeLevel}, groupType=${groupType}`);

        if (!gradeLevel) {
            console.warn("Skipping API call: No grade level selected.");
            return;
        }

        try {
            let endpoint = `/api/groups/grade-level-data/${gradeLevel}/`;

            const response = await axios.get(endpoint, {params: {group_type: groupType}});

            if (response.data?.error) {
                console.error("API Error:", response.data.error);
                alert(`API Error: ${response.data.error}`);
                return;
            }

            console.log("Fetched data:", response.data);

            setGroupData(prevData => ({
                ...prevData,
                [groupType]: {
                    groups: Array.isArray(response.data.groups) ? response.data.groups : [],
                    unassignedStudents: Array.isArray(response.data.unassigned_students) ? response.data.unassigned_students : [],
                },
            }));
        } catch (error) {
            console.error(`Error fetching data for groupType=${groupType}:`, error);
            alert('Error fetching data. Please check if the backend API is running and responding with JSON.');
        }
    };

    useEffect(() => {
        if (currentGroupType) {
            setLoading(true);
            fetchGradeLevelData(selectedGradeLevel, currentGroupType).then(() => {
                setLoading(false);
            });
        }
    }, [selectedGradeLevel, currentGroupType]);


    const createGroups = async () => {
        const numGroups = parseInt(prompt('Enter the number of groups to create:'), 10);
        if (!numGroups || numGroups <= 0) {
            alert('Please enter a valid number of groups.');
            return;
        }

        try {
            const response = await axios.post('/api/groups/grade-specific-auto-create-groups/', {
                grade_level_id: selectedGradeLevel,
                num_groups: numGroups,
                group_type: currentGroupType,
            }, {
                headers: {'X-CSRFToken': cookies.csrftoken},
            });

            alert(response.data.message);

            // Fetch updated data after creating groups
            await fetchGradeLevelData(selectedGradeLevel, currentGroupType);
        } catch (error) {
            console.error('Error creating groups:', error.message);
            alert('Error creating groups. Please try again.');
        }
    };

    const addGroupManually = () => {
        if (!newGroupName.trim()) {
            alert('Group name cannot be empty.');
            return;
        }

        axios.post('/api/groups/add-group/', {
            name: newGroupName,
            grade_level_id: selectedGradeLevel,
            group_type: currentGroupType,
        }, {
            headers: {'X-CSRFToken': cookies.csrftoken},
        })
            .then(response => {
                alert('Group added successfully!');
                setNewGroupName('');
                // Refresh data for the current grade level and group type
                fetchGradeLevelData(selectedGradeLevel, currentGroupType).then(r => {
                });
            })
            .catch(error => alert('Error adding group: ' + error.message));
    };


    const handleDrop = async (item, targetGroup) => {
        console.log('Dragged item:', item);
        console.log('Target group:', targetGroup);

        try {
            console.log('Current groupData:', groupData);

            const groupType = item.groupId
                ? Object.keys(groupData).find((type) =>
                    groupData[type]?.groups.some((group) => {
                        console.log(`Checking groupType ${type} for groupId ${item.groupId}`);
                        return group.id === item.groupId;
                    })
                )
                : currentGroupType; // Use currentGroupType for unassigned students

            if (!groupType) {
                console.error(`Error: Could not determine groupType for groupId ${item.groupId}`);
                return;
            }

            console.log('Dynamically determined groupType:', groupType);

            if (!targetGroup) {
                console.log('Moving student to unassigned list');
                await axios.post(`/api/groups/update_group_membership/`, {
                    student_id: item.id,
                    current_group_id: item.groupId,
                    new_group_id: null,
                }, {
                    headers: {'X-CSRFToken': cookies.csrftoken},
                });

                setGroupData((prevGroupData) => {
                    const updatedGroups = prevGroupData[groupType]?.groups.map((group) => {
                        if (group.id === item.groupId) {
                            console.log(`Before update - group ${group.id} students:`, group.students);
                            const updatedStudents = group.students.filter((student) => student.id !== item.id);
                            console.log(`After update - group ${group.id} students:`, updatedStudents);
                            return {
                                ...group,
                                students: updatedStudents,
                            };
                        }
                        return group;
                    });

                    const updatedUnassignedStudents = [
                        ...prevGroupData[groupType]?.unassignedStudents,
                        prevGroupData[groupType]?.groups
                            .flatMap((group) => group.students)
                            .find((student) => student.id === item.id),
                    ];

                    console.log('Updated unassignedStudents:', updatedUnassignedStudents);
                    console.log('Updated groups:', updatedGroups);

                    return {
                        ...prevGroupData,
                        [groupType]: {
                            groups: updatedGroups,
                            unassignedStudents: updatedUnassignedStudents,
                        },
                    };
                });

                console.log('Student successfully moved to unassigned list.');
                return;
            }

            console.log('Moving student between groups');
            await axios.post(`/api/groups/update_group_membership/`, {
                student_id: item.id,
                current_group_id: item.groupId,
                new_group_id: targetGroup.id,
            }, {
                headers: {'X-CSRFToken': cookies.csrftoken},
            });

            setGroupData((prevGroupData) => {
                const student = prevGroupData[groupType]?.unassignedStudents.find((s) => s.id === item.id) ||
                    prevGroupData[groupType]?.groups
                        .flatMap((group) => group.students)
                        .find((s) => s.id === item.id);

                if (!student || !student.id) {
                    console.error('Error: Invalid student object during state update:', student);
                    alert('An error occurred while updating the group. Please refresh and try again.');
                    return prevGroupData; // Return existing state to prevent corruption
                }

                const updatedGroups = prevGroupData[groupType]?.groups.map((group) => {
                    if (group.id === targetGroup.id) {
                        return {
                            ...group,
                            students: [...group.students, student], // Add the student to the target group
                        };
                    }
                    if (group.id === item.groupId) {
                        console.log(`Before update - group ${group.id} students:`, group.students);
                        const updatedStudents = group.students.filter((s) => s.id !== item.id);
                        console.log(`After update - group ${group.id} students:`, updatedStudents);
                        return {
                            ...group,
                            students: updatedStudents,
                        };
                    }
                    return group;
                });

                const updatedUnassignedStudents = prevGroupData[groupType]?.unassignedStudents.filter(
                    (s) => s.id !== item.id
                );

                console.log('Updated unassignedStudents:', updatedUnassignedStudents);
                console.log('Updated groups:', updatedGroups);

                return {
                    ...prevGroupData,
                    [groupType]: {
                        groups: updatedGroups,
                        unassignedStudents: updatedUnassignedStudents,
                    },
                };
            });

            console.log('Student successfully moved between groups.');
        } catch (error) {
            console.error('Error during handleDrop:', error.message);
            alert('Error updating group membership: ' + error.message);
        }
    };


    if (loading) {
        return <p>Loading...</p>;
    }


    return (
        <DndProvider backend={HTML5Backend}>
            <div className="groups-home">
                <h2 className="title">Manage Groups</h2>

                <div className="top-section">
                    <div className="filter-group">
                        <label htmlFor="grade-level-select">Grade Level:</label>
                        <select
                            id="grade-level-select"
                            value={selectedGradeLevel}
                            onChange={(e) => setSelectedGradeLevel(e.target.value)}

                        >
                            <option value="">--Select Grade Level--</option>
                            {gradeLevels.map(grade => (
                                <option key={grade.id} value={grade.id}>{grade.grade}</option>
                            ))}
                        </select>
                    </div>

                    <div className="filter-group">
                        <label htmlFor="group-type-select">Group Type:</label>
                        <select
                            id="group-type-select"
                            value={currentGroupType}
                            onChange={(e) => setCurrentGroupType(e.target.value)}
                        >
                            {groupTypes.map(type => (
                                <option key={type.key} value={type.key}>{type.label}</option>
                            ))}
                        </select>
                    </div>
                    <div className="filter-group">
                        <button onClick={createGroups}>Auto-Create Groups</button>
                        <input
                            type="text"
                            value={newGroupName}
                            onChange={(e) => setNewGroupName(e.target.value)}
                            placeholder="Enter new group name"
                        />
                        <button onClick={addGroupManually}>Add Group</button>
                    </div>
                </div>

                <div className="layout">
                    <UnassignedStudents
                        students={groupData[currentGroupType]?.unassignedStudents || []}
                        onDrop={handleDrop}
                    />

                    <div className="group-list">
                        {console.log('Rendering group-list with data:', groupData[currentGroupType]?.groups)}
                        {(groupData[currentGroupType]?.groups || []).map(group => (
                            <div key={group.id} className="group-container">
                                <div className="group-header">
                                    <h3>{group.name}</h3>
                                    <RemoveGroup
                                        groupId={group.id}
                                        groupName={group.name}
                                        onRemove={(removedGroupId, removedStudents) => {
                                            setGroupData(prevGroupData => {
                                                const updatedGroups = prevGroupData[currentGroupType]?.groups?.filter(
                                                    group => group.id !== removedGroupId
                                                ) || [];

                                                const updatedUnassignedStudents = [
                                                    ...prevGroupData[currentGroupType]?.unassignedStudents || [],
                                                    ...removedStudents?.filter(student => student && student.id).map(student => ({
                                                        id: student.id,
                                                        first_name: student.first_name,
                                                        last_name: student.last_name,
                                                    })) || []
                                                ];

                                                return {
                                                    ...prevGroupData,
                                                    [currentGroupType]: {
                                                        groups: updatedGroups,
                                                        unassignedStudents: updatedUnassignedStudents,
                                                    },
                                                };
                                            });
                                        }}
                                    />
                                </div>
                                {/* Droppable Group */}
                                <DroppableGroup
                                    key={group.id}
                                    group={group}
                                    onDrop={handleDrop}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </DndProvider>
    );


};

export default ManageGroups;
